import {UserService} from './services/user.service';
import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {PersistenceService, StorageType} from 'angular-persistence';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent {
  constructor(
    private userService: UserService,
    private router: Router,
    private persistenceeService: PersistenceService) {
    try {
      window.stop();
    } catch (exception) {
      document.execCommand('Stop');
    }

    this.userService.logout();

    if (this.persistenceeService.get('sesi', StorageType.LOCAL) == 'true') {
      this.router.navigate(['sesi']);
    } else {
      this.router.navigate(['']);
    }
  }
}
