import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {ServiceMain} from './service-main.service';
import {HttpClient} from '@angular/common/http';
import {PersistenceService} from 'angular-persistence';


@Injectable()
export class MedicoService extends ServiceMain {

  constructor(private _http: HttpClient,
              private persistenceService: PersistenceService) {
    super(_http, persistenceService);
  }

  getEspecialidades(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'publico/agendamento/especialidades', false);
  }

  getView(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'publico/medico/getview', false);
  }

  get(verificarDados: any): Observable<any> {
    return this.executaGET(verificarDados, 'publico/medico', false);
  }

  getEspecialidadesUnidade(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'publico/credenciado/getEspecialidadeUnidade', false)
  }
}
