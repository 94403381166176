import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {InicioComponent} from './inicio/inicio.component';
import {RouterOutletComponent} from './router-outlet.component';
import {AppRoutingModule} from './app-routing.module';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AgendamentoComponent} from './agendamento/agendamento.component';
import {LoginComponent} from './login/login.component';
import {CadastroComponent} from './cadastro/cadastro.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LocalidadeService} from './services/localidade.service';
import {MedicoService} from './services/medico.service';
import {CredenciadoService} from './services/credenciado.service';
import {CgcPipe} from './pipes/cgc.pipe';
import {AgendamentoService} from './services/agendamento.service';
import {PacienteService} from './services/paciente.service';
import {MenuComponent} from './menu/menu.component';
import {UserService} from './services/user.service';
import {AuthLogin} from './services/auth-login.service';
import {AuthGuard} from './services/auth-guard.service';
import {LogoutComponent} from './logout';
import {PagarPersonalComponent} from './pagamento/personal.component';
import {DateBrPipe} from './pipes/datebr.pipe';
import {NumberPipe} from './pipes/number.pipe';
import {CpfPipe} from './pipes/cpf.pipe';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FooterComponent} from './footer/footer.component';
import {RedeComponent} from './rede/rede.component';
import {ParceirosComponent} from './parceiros/parceiros.component';
import {RecargaComponent} from './recarga/recarga.component';
import {DadosSuaContaComponent} from './componentes/dados-sua-conta/dados-sua-conta';
import {PostmonCorreiosService} from './services/postmon.correios.services';
import {SendMailService} from './services/sendmail.service';
import {RelatorioConsultaAgendada} from './componentes/relarios/relatorio-consulta-agendada';
import {RelatorioConsultaRealizadas} from './componentes/relarios/relatorio-consulta-realizadas';
import {StimulsoftReportComponent} from './componentes/relarios/report/stimulsoft-report';
import {RelatorioCompraSaldo} from './componentes/relarios/relatorio-compra-saldo';
import {AgmCoreModule} from '@agm/core';
import {CepPipe} from './pipes/cep.pipe';
import {TrocarLoginComponent} from './trocar-senha/trocar-login.component';
import {LimparComponent} from './limpar';
import {FonePipe} from './pipes/fone.pipe';
import {DadosUsuarioComponent} from './dadosUsuario/dados.usuario.componet';
import {LaboratorioComponent} from './laboratorio/laboratorio.component';
import {HttpClientModule} from '@angular/common/http';
import {PersistenceModule} from 'angular-persistence';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    PersistenceModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCaYxlhZ1CvfDGNCAUyyagWgZ6ovPYmkis'
    }),
  ],
  declarations: [
    RouterOutletComponent,

    InicioComponent,

    AgendamentoComponent,
    RedeComponent,
    LaboratorioComponent,

    ParceirosComponent,


    LoginComponent,
    CadastroComponent,
    DashboardComponent,
    DadosUsuarioComponent,
    CgcPipe,
    DateBrPipe,
    NumberPipe,
    CpfPipe,
    CepPipe,
    FonePipe,
    MenuComponent,
    DadosSuaContaComponent,

    LogoutComponent,
    PagarPersonalComponent,
    RecargaComponent,
    FooterComponent,

    RelatorioConsultaRealizadas,
    RelatorioConsultaAgendada,
    StimulsoftReportComponent,
    RelatorioCompraSaldo,
    TrocarLoginComponent,
    LimparComponent
  ],
  bootstrap: [RouterOutletComponent],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    LocalidadeService,
    MedicoService,
    CredenciadoService,
    AgendamentoService,
    PacienteService,
    UserService,
    SendMailService,
    AuthLogin,
    PostmonCorreiosService,
    AuthGuard
  ]
})

export class AppModule {
}
