import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MedicoService } from "../services/medico.service";
import { SendMailService } from "../services/sendmail.service";

@Component({
  selector: "app-inicio",
  templateUrl: "./inicio.html",
  styleUrls: ["./inicio.css"],
  host: {
    "(window:resize)": "iniciarTemplate()",
  },
})
export class InicioComponent implements OnInit {
  entidade: any = {
    especialidade: "",
  };

  arrEspecialidades: any = [];

  emailContato: any = {
    nome: "",
    email: "",
    mensagem: "",
  };

  emailSolicitacao: any = {
    responsavel: "",
    telefone: "",
    nomeclinica: "",
    email: "",
    site: "",
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private medicoService: MedicoService,
    private sendMailService: SendMailService
  ) {}

  ngOnInit(): any {
    this.getEspecialidades();
  }

  showCallCenterMedico(): void {
    swal({
      title: "",
      html: true,
      text:
        '<div id="myCarousel" class="carousel slide" data-ride="carousel">\n' +
        "  <!-- Wrapper for slides -->\n" +
        '  <div class="carousel-inner" style="margin-top: -50px;">\n' +
        '    <div class="item active" style="background-color: #ffffff;">\n' +
        '       <div class="d-table text-center">\n' +
        '          <div style="background-color: #e7f0f8; vertical-align: initial !important; horiz-align: center !important;">\n' +
        '            <span  style="color: #094f70; font-size: 35px; margin-left: -50px;;">Pacotes</span><br>\n' +
        "          </div>\n" +
        '          <div style="background-color: #e7f0f8; vertical-align: initial !important;;margin-bottom: 10px;margin-top:10px;">\n' +
        '            <h1 style="color: #094f70; font-size: 35px;margin-left: -50px;">*Gratuito</h1>\n' +
        '            <p style="color: #094f70; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Desconto em Consulta</p>\n' +
        '            <p style="color: #094f70; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Agendamento</p>\n' +
        '            <p style="color: #094f70; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Pagamento das consultas via catão</p>\n' +
        '            <p style="color: #094f70; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">convênio com desconto em folha</p>\n' +
        "          </div>\n" +
        '          <div style="background-color: #09587d;  vertical-align: initial !important; border-bottom: 6px solid #327392;margin-bottom: 10px;margin-top:10px;">\n' +
        '            <h1 style="color: #FFFFFF; font-size: 35px;margin-left: -50px;">*R$ 7,90</h1>\n' +
        '            <p style="color: #FFFFFF; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Desconto em Consulta</p>\n' +
        '            <p style="color: #FFFFFF; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Agendamento</p>\n' +
        '            <p style="color: #ec1e29; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Orientação Médica por Telefone</p>\n' +
        '            <p style="color: #FFFFFF; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Plus Clube de Vantagens</p>\n' +
        '            <p style="color: #FFFFFF; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Desconto em Farmácias</p>\n' +
        "          </div>\n" +
        '          <div style="background-color: #e7f0f8; vertical-align: initial !important;margin-bottom: 10px;margin-top:10px;">\n' +
        '            <h1 style="color: #094f70; font-size: 35px;margin-left: -50px;">*R$ 29,90</h1>\n' +
        '            <p style="color: #094f70; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Desconto em Consulta</p>\n' +
        '            <p style="color: #094f70; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Agendamento</p>\n' +
        '            <p style="color: darkred; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Orientação Médica por Telefone</p>\n' +
        '            <p style="color: #094f70; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Plus Clube de Vantagens</p>\n' +
        '            <p style="color: #094f70; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Desconto em Farmácias</p>\n' +
        '            <p style="color: #094f70; font-size: 12px; font-weight: 400; text-indent: 0; margin: 0px; padding-bottom: 0px;">Planos Odontológico</p>\n' +
        "          </div>\n" +
        "        </div>" +
        "    </div>\n" +
        '    <div class="item">\n' +
        '     <h3 style="color: darkred;">Orientação Médica por Telefone: <br> 0800 941 5455</h3>\n' +
        '     <img src="assets/img/CallCenterMedico.png" style="width: 100%;">\n' +
        "    </div>\n" +
        "  </div>\n" +
        "  <!-- Left and right controls -->\n" +
        '  <a class="left carousel-control" href="#myCarousel" data-slide="prev"' +
        '     style="background-image: none; margin: -65px;">\n' +
        '    <span class="glyphicon glyphicon-chevron-left"></span>\n' +
        '    <span class="sr-only">Previous</span>\n' +
        "  </a>\n" +
        '  <a class="right carousel-control" href="#myCarousel" data-slide="next"' +
        '     style="background-image: none;     margin-top: -9px;">\n' +
        '    <span class="glyphicon glyphicon-chevron-right"></span>\n' +
        '    <span class="sr-only">Next</span>\n' +
        "  </a>\n" +
        "</div>",
      confirmButtonText: "OK",
    });
  }

  contato(contato): void {
    this.sendMailService.sendMailContato(contato).subscribe((response) => {
      if (response.success) {
        swal(
          "Envio de Contato",
          "Seu contato foi enviado com sucesso. Aguarde logo retornaremos o contato ",
          "success"
        );
      } else {
        swal(
          "Envio de Contato",
          "Seu Contato não foi enviado com successo, por favor tente mais tarde.",
          "error"
        );
      }
    });
  }

  solicitarCadastro(solicitacao): void {
    if (
      this.emailSolicitacao.responsavel == "" ||
      this.emailSolicitacao.telefone == "" ||
      this.emailSolicitacao.nomeclinica == "" ||
      this.emailSolicitacao.email == "" ||
      this.emailSolicitacao.site == ""
    ) {
      swal(
        "VALIDAÇÃO DA CLÍNICA",
        "Você precisa preencher todos os campos.",
        "error"
      );
      return;
    }
    this.sendMailService
      .sendMailSolicitarCadastro(solicitacao)
      .subscribe((response) => {
        if (response.success) {
          swal(
            "Envio de Contato",
            "Sua solicitação foi enviado com sucesso. Aguarde logo retornaremos o contato ",
            "success"
          );
        } else {
          swal(
            "Envio de Contato",
            "Sua solicitação não foi enviado com successo, por favor tente mais tarde.",
            "error"
          );
        }
      });
  }

  iniciarTemplate(): void {
    setTimeout(function () {
      $(".benefits .text").css("padding-bottom", 51);
      if ($(window).width() > 767) {
        $(".benefits li").each(function () {
          if ($(this).outerHeight() < $(".benefits").outerHeight()) {
            $(this)
              .find(".text")
              .css(
                "padding-bottom",
                $(".benefits").outerHeight() - $(this).outerHeight() + 51
              );
          }
        });
      } else if ($(window).width() > 479) {
        $(".benefits li:nth-child(even)").each(function () {
          let elem = $(this);
          if (elem.outerHeight() < elem.prev().outerHeight()) {
            elem
              .find(".text")
              .css(
                "padding-bottom",
                elem.prev().outerHeight() - elem.outerHeight() + 51
              );
          } else if ($(this).outerHeight() > $(this).prev().outerHeight()) {
            elem
              .prev()
              .find(".text")
              .css(
                "padding-bottom",
                elem.outerHeight() - elem.prev().outerHeight() + 51
              );
          }
        });
      }

      $("#doctors-carousel").owlCarousel({
        items: 4,
        itemsDesktop: false,
        itemsDesktopSmall: false,
        itemsTablet: [980, 3],
        itemsTabletSmall: [767, 2],
        itemsMobile: [479, 1],
        navigation: true,
        navigationText: [
          '<i class="fa fa-angle-left"></i>',
          '<i class="fa fa-angle-right"></i>',
        ],
        pagination: false,
      });

      $(".widget-departments").on("click", "dt", function () {
        $(this).toggleClass("opened").next().slideToggle("fast");
      });

      let width = $("body").width();
      $(".doctors-carousel-doctorday").css(
        "padding-bottom",
        (685 * width) / 1919 - 254
      );
    }, 1);
  }

  getEspecialidades(): void {
    this.arrEspecialidades = [];
    this.medicoService.getEspecialidades({}).subscribe((response) => {
      this.arrEspecialidades = response;

      const tree = this.router.parseUrl(this.router.url);

      setTimeout(function () {
        $(".widget-departments dt.opened").next().slideToggle("fast");

        if (tree.fragment) {
          setTimeout(function () {
            document.getElementById(tree.fragment).scrollIntoView();
          }, 1);
        }

        let width = $("body").width();
        $(".doctors-carousel-doctorday").css(
          "padding-bottom",
          (685 * width) / 1919 - 254
        );
      }, 1);

      this.iniciarTemplate();
    });
  }

  fazeragendamento(): any {
    if (this.entidade.especialidade == "") {
      swal(
        "Especialidade não selecionada",
        "Por favor para continuar Selecione uma especialidade",
        "error"
      );
    } else {
      this.router.navigate(["/agendamento/" + this.entidade.especialidade]);
    }
  }

  login(): any {
    this.router.navigate(["/agendamento/" + "1" + "/login"]);
  }
}
