import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PacienteService } from "../services/paciente.service";

@Component({
  selector: "app-cadastro",
  templateUrl: "./cadastro.html",
  styleUrls: ["./cadastro.css"],
})
export class CadastroComponent implements OnInit {
  entidade: any = {
    especialidade: "",
  };

  usuario: any = {
    nome: "",
    cpf: "",
    datanascimento: "",
    email: "",
    senha: "",
    confirmasenha: "",
    telefone: "",
    sexo: "",
  };

  constructor(
    private router: Router,
    private pacienteService: PacienteService
  ) {}

  ngOnInit(): void {
    setTimeout(function () {
      $(".mascaraCPF").mask("000.000.000-00");
      $(".mascaraDATA").mask("00/00/0000");
      $(".mascaraTEL").mask("(00) 0000-00000");
    }, 1);
  }

  cadastrar(): any {
    if (
      this.usuario.nome != "" &&
      this.usuario.cpf != "" &&
      this.usuario.datanascimento != "" &&
      this.usuario.email != "" &&
      this.usuario.senha != "" &&
      this.usuario.confirmasenha != "" &&
      this.usuario.telefone != "" &&
      this.usuario.sexo != ""
    ) {
      if (this.usuario.senha == this.usuario.confirmasenha) {
        const previousWindowKeyDown = window.onkeydown;
        let me = this;

        swal(
          {
            title: "Cadastro",
            text: "Você está prestes a se cadastrar na Agiliza Saúde!!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, cadastre!",
            closeOnConfirm: false,
            showLoaderOnConfirm: true,
          },
          function () {
            window.onkeydown = previousWindowKeyDown;

            me.pacienteService.post(me.usuario).subscribe((response) => {
              swal(
                {
                  title: "Cadastro",
                  text: "Cadastro efetuado com sucesso, aproveite todas as vantagens!",
                  type: "success",
                  showCancelButton: false,
                  closeOnConfirm: true,
                },
                function () {
                  me.router.navigate(["login"]);
                }
              );
            });
          }
        );
      } else {
        swal(
          "Senhas Diferentes",
          "Senha e Confirmar Senha são Diferentes",
          "error"
        );
      }
    } else {
      swal("Campos Vazios", "Existem Campos que estão Vazios", "error");
    }
  }
}
