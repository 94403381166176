import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {LocalidadeService} from '../services/localidade.service';
import {MedicoService} from '../services/medico.service';
import {AgendamentoService} from '../services/agendamento.service';
import {UserService} from '../services/user.service';

@Component({
  selector: 'app-agendamento',
  templateUrl: './agendamento.html',
  styleUrls: ['./agendamento.css']
})

export class AgendamentoComponent implements OnInit {
  gravarVerificacao = false;
  loadingEnvioEmail = false;
  enviarEmailSistema = '';
  entidade: any = {
    especialidade: '',
    estado: '',
    municipio: '',
    bairro: '',
    dataAgendamento: '',
    unidade: '',
    horario: '',
    profissional: ''
  };

  availableDates: any = [];

  arrEstados: any = [];
  arrMunicipios: any = [];
  arrBairros: any = [];

  arrEspecialidades: any = [];
  arrDatasConsultas = [];
  arrCredenciados: any = [];

  conteudoCarregado = false;
  conteudoCarregadoDatas = false;
  conteudoCarregadoMunicipio = true;
  conteudoCarregadoBairro = true;
  controleUnidadeHorario = 1;
  detalhe = false;

  var2: string;
  sub: Subscription;

  usuario = '';
  paciente = '';

  naoencontrado = false;
  arrMedicos: any = [];
  objetoCredenciado: any = {};

  passo = 1;

  @ViewChild('calendar', {static: true}) calendar: ElementRef;

  constructor(private route: ActivatedRoute,
              private localidadeService: LocalidadeService,
              private userService: UserService,
              private agendamentoService: AgendamentoService,
              private router: Router,
              private medicoService: MedicoService) {
  }

  agendar(objetoCredenciado: any): any {
    let me = this;
    let filtro = {
      CodigoEspecialidade: this.entidade.especialidade,
      CodigoPessoaUnidade: objetoCredenciado.CodigoPessoaUnidade,
      dataAgendamento: me.entidade.dataAgendamento
    };
    me.objetoCredenciado = objetoCredenciado;
    me.arrMedicos = [];
    me.conteudoCarregado = false;
    me.agendamentoService.getMedicosFichasUnidade(filtro).subscribe(
      response => {
        me.arrMedicos = response.Medicos;
        me.conteudoCarregado = true;
        me.controleUnidadeHorario = 3;

        setTimeout(function () {
          $('.detailed-services dt.opened').next().slideToggle('fast');

          $('.detailed-services').on('click', 'dt', function () {
            $(this).toggleClass('opened').next().slideToggle('fast');
          });
        }, 1);
      }
    );
  }

  agendarConsulta(consulta: any): any {
    let me = this;
    swal({
      title: 'Agendar?',
      text: 'Você será direcionado para a área de login para efetuar o pagamento e efetivar sua consulta! \n' +
        'ATENÇÃO: Cancelamentos e reagendamentos até 12 horas de antecedencia. \n\n' +
        'Informamos caso não haja a solicitação de cancelamento antecipado a consulta será cobrada',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, agendar!',
      cancelButtonText: 'Não, cancelar.',
      closeOnConfirm: true
    }, function () {
      consulta.tipoconsulta = 5;
      let objetoConsulta: any = {
        consulta: consulta,
        credenciado: me.objetoCredenciado,
        dataconsulta: me.entidade.dataAgendamento,
        codigoespecialidade: me.entidade.especialidade
      };
      me.userService.setAgendamento(objetoConsulta);
      me.router.navigate(['login']);
    });
  }

  getEspecialidades(): void {
    this.arrEspecialidades = [];
    this.conteudoCarregado = false;
    this.medicoService.getEspecialidades({}).subscribe(
      response => {
        this.arrEspecialidades = response;
        let id = this.route.snapshot.params['id'];
        let rota = this.route.snapshot.params['rota'];

        if (rota !== undefined) {
          this.passo = 3;
        }

        if (id !== undefined) {
          this.entidade.especialidade = id;
          this.getEstados();
        } else {
          this.conteudoCarregado = true;
        }
      }
    );
  }

  selecionarEspecialidade(especialidade): void {
    this.entidade.especialidade = especialidade;
    this.controleUnidadeHorario = 1;

    this.entidade.estado = '';
    this.entidade.municipio = '';
    this.entidade.bairro = '';

    this.getEstados();
  }

  getBairros(cidade: any): void {
    this.arrBairros = [];
    this.entidade.bairro = '';
    this.conteudoCarregadoBairro = false;
    this.localidadeService.getBairros({especialidade: this.entidade.especialidade, municipio: cidade}).subscribe(
      response => {
        this.arrBairros = response;
        this.conteudoCarregadoBairro = true;
        this.getListaDatas();
      }
    );
  }

  getMunicipios(cuf: any): void {
    this.arrMunicipios = [];
    this.arrBairros = [];
    this.entidade.municipio = '';
    this.entidade.bairro = '';
    this.conteudoCarregadoMunicipio = false;
    this.localidadeService.getMunicipios({especialidade: this.entidade.especialidade, estado: cuf}).subscribe(
      response => {

        this.arrMunicipios = response;
        this.arrBairros = [];
        this.conteudoCarregadoMunicipio = true;
        this.getListaDatas();
      }
    );
  }

  getEstados(): void {
    this.conteudoCarregado = false;
    this.arrEstados = [];
    this.arrMunicipios = [];
    this.arrBairros = [];
    this.entidade.municipio = '';
    this.entidade.bairro = '';
    this.localidadeService.getEstados({especialidade: this.entidade.especialidade}).subscribe(
      response => {
        this.conteudoCarregado = true;
        this.arrEstados = response;
        this.getListaDatas();
      }
    );
  }

  getListaDatasBairro(bairro: any): void {
    this.getListaDatas();
  }

  enviarEmailSolicitacao(): any {
    let me  = this;
    let customSwal: any = swal;
    me.loadingEnvioEmail = true;
    if (me.enviarEmailSistema == '') {
      customSwal.showInputError('Entre com o E-mail');
      return false;
    }
    let parametros: any = {
      email : me.enviarEmailSistema,
      estado : me.entidade.estado,
      municipio : me.entidade.municipio,
      especialidade : me.entidade.especialidade
    };
    me.agendamentoService.setGravarEmailPessoa(parametros).subscribe(
      response2  => {
        if (response2.success) {
          swal('E-MAIL ENVIADO', 'Recebemos o seu E-mail Solicitando vagas nesse municipio, entraremos em contato', 'success');
        } else {
          swal('E-MAIL NÃO ENVIADO', 'Não pode ser enviado o e-mail', 'error');
        }
        me.loadingEnvioEmail = false;
      }
    );
  }

  getListaDatas(mes = '', ano = ''): void {
    this.conteudoCarregadoDatas = false;
    this.arrDatasConsultas = [];
    let me = this;
    let filtro = {
      especialidade: this.entidade.especialidade,
      estado: this.entidade.estado,
      municipio: this.entidade.municipio,
      bairro: this.entidade.bairro,
      mes: mes,
      ano: ano
    };
    let previousWindowKeyDown = window.onkeydown;
    me.gravarVerificacao = false;
    me.agendamentoService.getListaDatas(filtro).subscribe(
      response => {
        me.conteudoCarregadoDatas = true;
        me.availableDates = response.datas;
        if (response.gravarVerificacao) {
          me.gravarVerificacao = true;
        } else {
          $(me.calendar.nativeElement).datepicker('refresh');
        }

        /*if (response.gravarVerificacao) {
          let customSwal: any = swal;
          customSwal({
            title: 'SEM VAGAS',
            text: 'No momento todas as vagas para a especialidade selecionada foram ocupadas, iremos trabalhar para liberar novas vagas e entraremos em contato, ' +
              'favor confirmar seu E-MAIL abaixo:\n',
            type: 'input',
            inputType: 'email',
            showCancelButton: true,
            confirmButtonText: 'Enviar E-mail',
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            closeOnConfirm: false
          }, function (email: any) {
            window.onkeydown = previousWindowKeyDown;
            if (email == false) {
              return false;
            }

            if (email == '') {
              customSwal.showInputError('Entre com o E-mail');
              return false;
            }
            let parametros: any = {
              email : email,
              estado : me.entidade.estado,
              municipio : me.entidade.municipio,
              especialidade : me.entidade.especialidade
            };

            me.agendamentoService.setGravarEmailPessoa(parametros).subscribe(
              response2  => {
                if (response2.success) {
                  swal('E-MAIL ENVIADO', 'Recebemos o seu E-mail Solicitando vagas nesse municipio, entraremos em contato', 'success');
                } else {
                  swal('E-MAIL NÃO ENVIADO', 'Não pode ser enviado o e-mail', 'error');
                }
              }
            );
          });

        }*/


      }
    );
  }

  validaDatasVisualizacao(date: any): any {
    let dia = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
    let mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    let ano = date.getFullYear();
    let dataFull = dia + '-' + mes + '-' + ano;
    if ($.inArray(dataFull, this.availableDates) != -1) {
      return true;
    } else {
      return false;
    }
  }

  initialzeDates(): void {
    // debugger;
    let me = this;
    let startDate = new Date();
    startDate.setDate(startDate.getDate());

    $(this.calendar.nativeElement).datepicker('destroy');
    $(this.calendar.nativeElement).datepicker({
      prevText: '<i class="fa fa-angle-left"></i>',
      nextText: '<i class="fa fa-angle-right"></i>',
      minDate: startDate,
      dateFormat: 'dd/mm/yy',
      beforeShowDay: function (dt) {
        return [me.validaDatasVisualizacao(dt), ''];
      },
      onSelect: function (dateText) {
        let date = $(this).datepicker('getDate'),
          month = date.getMonth() + 1,
          year = date.getFullYear();

        me.detalhe = false;
        me.entidade.dataAgendamento = dateText;
        me.selecionarUnidadesMedicas(dateText, month, year);
      },
      onChangeMonthYear: function (year, month) {
        me.getListaDatas(month, year);
      },
      language: 'pt-BR'
    });
  }

  selecionarUnidadesMedicas(dateText: any, mes: any, ano: any): void {
    this.controleUnidadeHorario = 0;
    this.arrDatasConsultas = [];
    let filtro = {
      especialidade: this.entidade.especialidade,
      estado: this.entidade.estado,
      municipio: this.entidade.municipio,
      bairro: this.entidade.bairro,
      data: dateText,
      mes: mes,
      ano: ano
    };
    this.agendamentoService.getListaDatasEspecialidadeUnidade(filtro).subscribe(
      response => {
        this.controleUnidadeHorario = 2;
        this.arrCredenciados = response.credenciados;

        setTimeout(function () {
          $('.detailed-services dt.opened').next().slideToggle('fast');

          $('.detailed-services').on('click', 'dt', function () {
            $(this).toggleClass('opened').next().slideToggle('fast');
          });
        }, 1);
      });
  }

  ngOnInit(): any {
    let me = this;
    me.conteudoCarregado = false;
    me.naoencontrado = true;
    me.detalhe = false;

    $('.tooltips').tooltip();
    this.initialzeDates();

    me.getEspecialidades();
  }
}
