import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {ServiceMain} from './service-main.service';
import {HttpClient} from '@angular/common/http';
import {PersistenceService} from 'angular-persistence';

@Injectable()
export class LocalidadeService extends ServiceMain {

  constructor(private _http: HttpClient,
              private persistenceService: PersistenceService) {
    super(_http, persistenceService);
  }

  getEstados(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'publico/localidade/estados', false);
  }

  getMunicipios(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'publico/localidade/municipios', false);
  }

  getMunicipiosRedeFarmacias(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'site/localidade/municipiosfarmacias');
  }

  getBairrosRedeFarmacias(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'site/localidade/bairrosfarmacias');
  }

  getBairros(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'publico/localidade/bairros', false);
  }

  getLogradouroTipo(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'publico/localidade/logradourotipo', false);
  }

  getLogradouro(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'publico/localidade/logradouro', false);
  }

  getSolicitarCep(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'publico/localidade/solicitarcep', false);
  }

}
