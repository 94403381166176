import {
  Component, OnInit
} from '@angular/core';
import {Router} from '@angular/router';
import {PacienteService} from '../../services/paciente.service';

@Component({
  selector: 'relatorio-consulta-realizadas',
  templateUrl: './relatorio-consulta-realizadas.html'
})
export class RelatorioConsultaRealizadas implements OnInit {
  filtro: any;
  conteudoCarregado = true;
  conteudoConsultas = true;
  carregaRelatorio = false;

  caminhoRelatorio = 'assets/reports/relatorio_consultas_realizadas.mrt';
  dadosRelatorio: any = {};

  perfilUsuario = 0;
  constructor( private router: Router,
            private servico: PacienteService ) {

    this.filtro = {
      dataconsulta: '',
      clinica: '',
      medico: ''
    };

  }

  filtrarDataGridLocal(filtros: any): any {
    this.carregaRelatorio = false;
    this.conteudoConsultas = false;
    this.conteudoCarregado = false;
    this.servico.getConsultasRealizadas(filtros)
      .subscribe(
        response => {
          this.conteudoCarregado = true;
          this.conteudoConsultas = true;
          if (response) {
            let dados = { cabec : response.cabec, lista :  response.lista  }
            this.dadosRelatorio = [];
            this.dadosRelatorio = dados;
            this.carregaRelatorio = true;
          } else {
            this.carregaRelatorio = true;
          }
          this.conteudoCarregado = true;
        },
        error => {
          let $this = this;
          if (error.status == 401) {
            swal({
              title: 'Token Expirado!',
              text: 'Por favor, entre novamente no sistema.',
              type: 'error',
              closeOnConfirm: true
            }, function () {
              $this.router.navigate(['']);
            });
          }
        }
      );
  }

  ngOnInit(): any {
    this.filtrarDataGridLocal(this.filtro);

  }

}
