import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {PacienteService} from '../services/paciente.service';

@Component({
  selector: 'dados-usuario',
  templateUrl: './dados-usuario.html'
})

export class DadosUsuarioComponent implements OnInit {

  CodigoPessoa: any;
  dataPaciente = {};

  constructor(private route: ActivatedRoute,
              private router: Router,
              private pacienteService: PacienteService) {
    this.route.params.subscribe(res => this.CodigoPessoa = atob(res.id));

  }

  ngOnInit(): void {
    let $this = this;

    $this.aplicaMask();
    $this.pacienteService.getView({CodigoPessoa: $this.CodigoPessoa})
      .subscribe(
        response => {
              if (response.success) {
                $this.dataPaciente = response.paciente;
              } else {
                swal('', response.message, 'error');
              }
      });
  }

  aplicaMask(): void {
    setTimeout(function () {
      $('.mascaraCPF').mask('000.000.000-00');
      $('.mascaraDATA').mask('00/00/0000');
      $('.mascaraTEL').mask('(00) 0000-00000');
      $('.mascaraCEP').mask('00.000-000');

    }, 1);
  }

  Save(dataPaciente): void {
    let $this = this;
    $this.pacienteService.update(dataPaciente)
      .subscribe(
        response => {
              if (response.success) {
                swal('Sucesso!', 'Cadastro Atualizado com Sucesso!', 'success');
              }
        }
      )
  }

  Cancelar(): void {
    this.router.navigate(['dashboard']);
  }
}
