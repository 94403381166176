import {Injectable} from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {PersistenceService, StorageType} from 'angular-persistence';
import {UserService} from './user.service';

@Injectable()
export class AuthLogin implements CanActivate {

  constructor(private router: Router,
              private persistenceService: PersistenceService,
              private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {

    return this.userService.validarLogin(() => {
    }, () => this.router.navigate(['dashboard']), true);
  }
}
