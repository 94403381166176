import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {ServiceMain} from './service-main.service';
import {HttpClient} from '@angular/common/http';
import {PersistenceService} from 'angular-persistence';
@Injectable()
export class PacienteService extends ServiceMain {

  constructor(private _http: HttpClient,
              private persistenceService: PersistenceService) {
    super(_http, persistenceService);
  }

  getView(filtro: any): Observable<any> {
    return this.executaGET(filtro, 'publico/paciente/getview', false);
  }

  get(verificarDados: any): Observable<any> {
    return this.executaGET(verificarDados, 'publico/paciente', false);
  }

  getDashboard(dados: any): Observable<any> {
    return this.executaGET(dados, 'site/getdashboard');
  }

  getDadosUsuario(dados: any): Observable<any> {
    return this.executaGET(dados, 'site/getdadosusuario');
  }

  post(dados: any): Observable<any> {
    return this.executaPOST(dados, 'publico/site/cadastrar', false);
  }

  getConsultasAgendadas(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'site/getConsultasAgendadas');
  }

  getConsultasRealizadas(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'site/getConsultasRealizadas');
  }

  getConsultasComprarSaldo(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'site/getConsultasComprarSaldo');
  }

  edit(dados: any): Observable<any> {
    return this.executaPUT({
      dados: dados
    }, 'paciente');
  }

  update(dados: any): Observable<any> {
    return this.executaPUT({
      dados: dados
    }, 'publico/completaCadastro', false);
  }

  getCreditCardPaciente(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'site/paciente/getcreditcardpaciente');
  }

}
