import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {PersistenceService, StorageType} from 'angular-persistence';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.html',
  styleUrls: ['menu.css']
})
export class MenuComponent implements OnInit {
  router: Router;
  userr: UserService;

  sesi = false;
  sesiac = false;
  sesisc = false;
  cdllages = false;
  fibra = false;

  offset: any;

  @ViewChild('menuFixed', {static: false}) menuFixed: ElementRef;

  constructor(
    private routerr: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private persistenceeService: PersistenceService) {
    this.router = routerr;
  }

  ngOnInit() {
    let me = this;

    this.userr = this.userService;

    let empresa = this.persistenceeService.get('empresa', StorageType.LOCAL);

    if (this.route.snapshot.data['tipo']) {
      if (this.route.snapshot.data['tipo'] == 'sesi') {
        this.persistenceeService.set('empresa', 'PR', {type: StorageType.LOCAL});
        this.sesi = true;
        this.sesiac = false;
        this.cdllages = false;
        this.sesisc = false;
        this.fibra = false;
      } if (this.route.snapshot.data['tipo'] == 'sesisc') {
        this.persistenceeService.set('empresa', 'SC', {type: StorageType.LOCAL});
        this.sesi = false;
        this.sesiac = false;
        this.cdllages = false;
        this.sesisc = true;
        this.fibra = false;
      } else if (this.route.snapshot.data['tipo'] == 'sesiac') {
        this.persistenceeService.set('empresa', 'AC', {type: StorageType.LOCAL});
        this.sesiac = true;
        this.sesi = false;
        this.cdllages = false;
        this.sesisc = false;
        this.fibra = false;
      } else if (this.route.snapshot.data['tipo'] == 'cdllages') {
        this.persistenceeService.set('empresa', 'SC', {type: StorageType.LOCAL});
        this.sesiac = false;
        this.sesi = false;
        this.cdllages = true;
        this.sesisc = false;
        this.fibra = false;
      } else if (this.route.snapshot.data['tipo'] == 'fibra') {
        this.persistenceeService.set('empresa', 'SC', {type: StorageType.LOCAL});
        this.sesiac = false;
        this.sesi = false;
        this.cdllages = false;
        this.sesisc = false;
        this.fibra = true;
      } else {
        this.sesiac = false;
        this.sesi = false;
        this.cdllages = false;
        this.sesisc = false;
        this.fibra = false;
      }
    } else if (empresa && empresa != '') {
      if (empresa == 'AC') {
        this.sesiac = true;
        this.sesi = false;
        this.cdllages = false;
        this.sesisc = false;
      } if (empresa == 'SC') {
        this.sesiac = false;
        this.sesi = false;
        this.cdllages = false;
        this.sesisc = true;
      } else {
        this.sesi = true;
        this.sesiac = false;
        this.cdllages = false;
        this.sesisc = false;
      }
    } else {
      this.sesi = false;
      this.sesiac = false;
      this.cdllages = false;
      this.sesisc = false;
    }

    setTimeout(function () {
      $('#mobile-main-nav-doctorday').on('click', 'i', function () {
        $(this).nextAll('ul').slideToggle('fast');
      });
      $('#mobile-main-nav-doctorday').on('click', '.opener', function () {
        $(this).next('ul').slideToggle('fast');
        return false;
      });

      me.offset = $(me.menuFixed.nativeElement).offset().top;

      $(document).on('scroll', function () {
        let meuMenu = $(me.menuFixed.nativeElement);

        if (me.offset <= $(window).scrollTop()) {
          meuMenu.addClass('fixar');
        } else {
          meuMenu.removeClass('fixar');
        }
      });
    }, 100);

    this.persistenceeService.remove('sesi', StorageType.LOCAL);
    this.persistenceeService.remove('sesiac', StorageType.LOCAL);

  }

  goScroll(link): void {
    try {
      document.getElementById(link).scrollIntoView();

      let scrolledY = window.scrollY;

      if (scrolledY) {
        window.scroll(0, scrolledY - 93);

        let meuMenu = $(this.menuFixed.nativeElement);

        if (this.offset <= $(window).scrollTop()) {
          meuMenu.addClass('fixar');
        } else {
          meuMenu.removeClass('fixar');
        }
      }
    } catch (ss) {
    }
  }

  goTop(): void {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 1);
  }
}
