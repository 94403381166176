import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {ServiceMain} from './service-main.service';
import {HttpClient} from '@angular/common/http';
import {PersistenceService} from 'angular-persistence';


@Injectable()
export class CredenciadoService extends ServiceMain {

  constructor(private _http: HttpClient,
              private persistenceService: PersistenceService) {
    super(_http, persistenceService);
  }

  get(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'publico/credenciado', false);
  }

  getListaDatas(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'publico/credenciado/getlistaespecialidade', false);
  }

  getListaDatasEspecialidadeUnidade(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'publico/credenciado/getlistaespecialidadeunidade', false);
  }

  getEsp(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'publico/credenciado/esp', false);
  }

  validarCgc(filtros: any): Observable<any> {
    return this.executaPOST(filtros, 'publico/credenciado/validarCgc', false);
  }

  add(filtros: any): Observable<any> {
    return this.executaPOST(filtros, 'publico/credenciado', false);
  }

  att(filtros: any): Observable<any> {
    return this.executaPUT(filtros, 'publico/credenciado', false);
  }
}
