import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {InicioComponent} from './inicio/inicio.component';
import {AgendamentoComponent} from './agendamento/agendamento.component';
import {LoginComponent} from './login/login.component';
import {CadastroComponent} from './cadastro/cadastro.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthLogin} from './services/auth-login.service';
import {AuthGuard} from './services/auth-guard.service';
import {LogoutComponent} from './logout';
import {PagarPersonalComponent} from './pagamento/personal.component';
import {RedeComponent} from './rede/rede.component';
import {ParceirosComponent} from './parceiros/parceiros.component';
import {RecargaComponent} from './recarga/recarga.component';
import {TrocarLoginComponent} from './trocar-senha/trocar-login.component';
import {LimparComponent} from './limpar';
import {DadosUsuarioComponent} from './dadosUsuario/dados.usuario.componet';
import {LaboratorioComponent} from './laboratorio/laboratorio.component';

export const appRoutes: Routes = [
  {path: '', component: InicioComponent},
  {path: 'sesi', component: InicioComponent, data: {tipo: 'sesi'}},
  {path: 'cdllages', component: InicioComponent, data: {tipo: 'cdllages'}},
  {path: 'sesiac', component: InicioComponent, data: {tipo: 'sesiac'}},
  {path: 'sesisc', component: InicioComponent, data: {tipo: 'sesisc'}},
  {path: 'fibra', component: InicioComponent, data: {tipo: 'fibra'}},

  {path: 'agendamento', component: AgendamentoComponent},
  {path: 'agendamento/:id', component: AgendamentoComponent},
  {path: 'agendamento/:id/:rota', component: AgendamentoComponent},

  {path: 'rede', component: RedeComponent},
  {path: 'laboratorio', component: LaboratorioComponent},
  {path: 'parceiros', component: ParceirosComponent},

  {path: 'login', component: LoginComponent, canActivate: [AuthLogin]},
  {path: 'trocar-login', component: TrocarLoginComponent, canActivate: [AuthLogin]},
  {path: 'cadastro', component: CadastroComponent, canActivate: [AuthLogin]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'pagarpersonal', component: PagarPersonalComponent, canActivate: [AuthGuard]},
  {path: 'recarga', component: RecargaComponent, canActivate: [AuthGuard]},
  {path: 'dadosCadastras/:id', component: DadosUsuarioComponent, canActivate: [AuthGuard]},

  {path: 'logout', component: LogoutComponent},
  {path: 'limpar', component: LimparComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
