import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {ServiceMain} from './service-main.service';
import {HttpClient} from '@angular/common/http';
import {PersistenceService} from 'angular-persistence';


@Injectable()
export class AgendamentoService extends ServiceMain {

  constructor(private _http: HttpClient,
              private persistenceService: PersistenceService) {
    super(_http, persistenceService);
  }

  getListaDatas(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'publico/agendamento/getlistaespecialidade', false);
  }

  setGravarEmailPessoa(dados: any): Observable<any> {
    return this.executaPOST(dados, 'publico/agendamento/setGravarEmailPessoa', false);
  }

  getMedicosFichasUnidade(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'publico/agendamento/getmedicosfichasunidade', false);
  }

  getListaDatasEspecialidadeUnidade(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'publico/agendamento/getlistaespecialidadeunidade', false);
  }

  executarAgendamento(dados: any): Observable<any> {
    return this.executaPOST(dados, 'site/agendamento/agendarpacienteunidade');
  }

  executarPagamento(dados: any): Observable<any> {
    return this.executaPOST(dados, 'site/pagamento/pagarpersonal');
  }

  executarPagamentoDoctorday(dados: any): Observable<any> {
    return this.executaPOST(dados, 'site/pagamento/pagardoctorday');
  }

  pagseguroGetSession(dados: any): Observable<any> {
    return this.executaGET(dados, 'site/pagamento/pagsegurosession');
  }

  pagseguroEfetuarPagamento(dados: any): Observable<any> {
    return this.executaPOST(dados, 'site/pagamento/pagseguroefetuarpagamento');
  }

  getListaUnidade(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'publico/credenciado/getlista', false);
  }

  getListaUnidadePersonalFarmcias(filtros: any): Observable<any> {
    return this.executaGET(filtros, 'site/lojascredenciadaspersonal');
  }

  ttemail(filtro: any): Observable<any> {
    return this.executaPOST(filtro, 'publico/enviaremailsobreagendamento', false);
  }

  alterarStatusConsulta(dados: any): Observable<any> {
    return this.executaPOST(dados, 'site/consulta/alterarStatus');
  }
}
