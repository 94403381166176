import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PacienteService} from '../../services/paciente.service';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'dados-sua-conta',
  templateUrl: './dados-sua-conta.html',
  styleUrls: ['./dados-sua-conta.css']
})

export class DadosSuaContaComponent implements OnInit {
  objetoUsuario: any = {};

  conteudoConsultas = true;
  relatorioConsultaAgendada = false;
  relatorioConsultaRealizada = false;
  relatorioCompraComSaldo = false;

  constructor(private userService: UserService, private router: Router, private pacienteService: PacienteService) { }

  carregarDadosUsuario(): any {
    this.conteudoConsultas = false;
    this.pacienteService.getDadosUsuario({}).subscribe(
      response => {
        this.objetoUsuario = response.dadosUsuario;
        this.conteudoConsultas = true;
        }
    );
  }

  mostrarRelatorio(relatorio): any {
    this.relatorioConsultaAgendada = false;
    this.relatorioConsultaRealizada = false;
    this.relatorioCompraComSaldo = false;

    switch ( relatorio ) {
      case 'relatorioConsultaAgendadas':
        this.relatorioConsultaAgendada = true;
        break;
      case 'relatorioConsultaRealizadas':
        this.relatorioConsultaRealizada = true;
        break;
      case 'relatorioCompraComSaldo':
        this.relatorioCompraComSaldo = true;
        break;
    }
  }

  fecharModal(): any {
    this.relatorioConsultaAgendada = false;
    this.relatorioConsultaRealizada = false;
    this.relatorioCompraComSaldo = false;
  }

  colocarCredito(): any {
    let me = this;
    me.userService.setPrepararPagamento(null);
    me.router.navigate(['recarga']);
  }

  ngOnInit(): any {
    let me = this;
    setTimeout(function () {
      $('.widget-contacts dt.opened').next().slideToggle('fast');

      $('.widget-contacts').on('click', 'dt', function () {
        $(this).toggleClass('opened').next().slideToggle('fast');
      });

      jQuery('.wpb_tabs, .wpb_tour').each(function (index) {
        let $tabs,
          interval = parseFloat(jQuery(this).attr('data-interval')),
          tabs_array = [];

        $tabs = jQuery(this).find('.wpb_tour_tabs_wrapper').tabs({
          show: function (event, ui) {
            me.wpb_prepare_tab_content(event, ui);
          },
          activate: function (event, ui) {
            me.wpb_prepare_tab_content(event, ui);
          }
        }).tabs('rotate', interval * 1000);

        jQuery(this).find('.wpb_tab').each(function () {
          tabs_array.push(this.id);
        });

        jQuery(this).find('.wpb_tab a[href^="#"]').click(function (e) {
          e.preventDefault();
          if (jQuery.inArray(jQuery(this).attr('href'), tabs_array)) {
            $tabs.tabs('select', jQuery(this).attr('href'));
            return false;
          }
        });

        jQuery(this).find('.wpb_prev_slide a, .wpb_next_slide a').click(function (e) {
          e.preventDefault();
          let ver = jQuery.ui.version.split('.');
          if (parseInt(ver[0]) == 1 && parseInt(ver[1]) < 9) {
            let indexx = $tabs.tabs('option', 'selected');

            if (jQuery(this).parent().hasClass('wpb_next_slide')) {
              indexx++;
            } else {
              indexx--;
            }

            if (indexx < 0) {
              indexx = $tabs.tabs('length') - 1;
            } else if (indexx >= $tabs.tabs('length')) {
              indexx = 0;
            }

            $tabs.tabs('select', indexx);
          } else {
            let indexx = $tabs.tabs('option', 'active'),
              length = $tabs.find('.wpb_tab').length;

            if (jQuery(this).parent().hasClass('wpb_next_slide')) {
              indexx = (indexx + 1) >= length ? 0 : indexx + 1;
            } else {
              indexx = indexx - 1 < 0 ? length - 1 : index - 1;
            }

            $tabs.tabs('option', 'active', indexx);
          }

        });

      });
      me.carregarDadosUsuario();
    }, 1);
  }

  editaCadastro(CodigoPessoa): void {
    let me = this;
    me.router.navigate(['dadosCadastras/' + btoa(CodigoPessoa)])
  }

  private wpb_prepare_tab_content(event, ui) {
    let panel = ui.panel || ui.newPanel;

    let $ui_panel = jQuery(panel).find('.isotope'),
      $google_maps = jQuery(panel).find('.wpb_gmaps_widget');
    if ($ui_panel.length > 0) {
      $ui_panel.isotope('reLayout');
    }

    if ($google_maps.length && !$google_maps.is('.map_ready')) {
      let $frame = $google_maps.find('iframe');
      $frame.attr('src', $frame.attr('src'));
      $google_maps.addClass('map_ready');
    }
  }
}
