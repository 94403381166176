import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {PersistenceService, StorageType} from 'angular-persistence';

@Component({
  selector: 'app-limpar',
  template: ''
})
export class LimparComponent {
  constructor(private router: Router,
              private persistenceeService: PersistenceService) {
    try {
      window.stop();
    } catch (exception) {
      document.execCommand('Stop');
    }

    this.persistenceeService.remove('sesi', StorageType.LOCAL);
    this.persistenceeService.remove('empresa', StorageType.LOCAL);

    this.router.navigate(['']);
  }
}
