import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {PacienteService} from '../services/paciente.service';
import {UserService} from '../services/user.service';
import {AgendamentoService} from '../services/agendamento.service';
import {MedicoService} from '../services/medico.service';
import {LocalidadeService} from '../services/localidade.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.css']
})

export class DashboardComponent implements OnInit {
  objetoUsuario: any = {};

  arrUltimasConsultas = [];
  arrConsultasRealizadas = [];
  arrPendentesConsultas = [];
  arrConfirmacaoConsultas = [];
  arrUltimasCompras = [];
  arrConsultasSessoes = [];
  arrBairros = [];
  arrMunicipios = [];
  arrEstados = [];
  arrCredenciados = [];

  arrEspecialidades: any = [];
  valorSaldo = '0,00';
  conteudoCarregado = true;
  conteudoConsultasPreAgendadas = false;
  conteudoCarregadoBairro = true;
  conteudoCarregadoMunicipio = true;
  conteudoCarregadoUnidades = true;

  filtroRede: any = {
    estado: '',
    municipio: '',
    bairro: '',
    segmento : '000016'
  };

  textoCarregandoMunicipio: any = {
    desabilitado : true,
    msg: 'Selecione o Municipio'
  };

  textoCarregandoBairro: any = {
    desabilitado : true,
    msg: 'Selecione um Bairro'
  };

  @ViewChild('commentscarousel', {static: false}) commentscarousel: ElementRef;
  @ViewChild('commentscarousel2', {static: false}) commentscarousel2: ElementRef;
  @ViewChild('commentscarousel3', {static: false}) commentscarousel3: ElementRef;

  @ViewChild('accordion', {static: false}) accordion: ElementRef;

  constructor(private router: Router,
              private localidadeService: LocalidadeService,
              private userService: UserService,
              private pacienteService: PacienteService,
              private agendamentoService: AgendamentoService,
              private medicoService: MedicoService) {

    this.userService.setPrepararPagamento(undefined);
    this.userService.setPrepararPagamentoSessao(undefined);
  }

  AbrirRedeCredenciada(): any {

  }

  fecharModal(): any {

  }

  selecionarUnidadesMedicas(): void {
    this.conteudoCarregadoUnidades = false;

    let filtro = {
      estado: this.filtroRede.estado,
      municipio: this.filtroRede.municipio.trim(),
      bairro: this.filtroRede.bairro.trim(),
      segmento: this.filtroRede.segmento.trim()
    };

    this.agendamentoService.getListaUnidadePersonalFarmcias(filtro).subscribe(
      response => {
        this.arrCredenciados = response;
        this.conteudoCarregadoUnidades = true;

        setTimeout(function () {
          $('.detailed-services dt.opened').next().slideToggle('fast');

          $('.detailed-services').on('click', 'dt', function () {
            $(this).toggleClass('opened').next().slideToggle('fast');
          });
        }, 1);
      });
  }

  getListaDatasBairro(bairro): void {
    this.filtroRede.bairro = bairro;
  }

  getListaDatasSegmento(segmento): void {
    this.filtroRede.segmento = segmento;
    // this.selecionarUnidadesMedicas();
  }

  getBairros(cidade: any): void {
    this.arrBairros = [];
    this.filtroRede.bairro = '';
    this.conteudoCarregadoBairro = false;
    this.textoCarregandoBairro.msg = 'Aguarde Carregando dados...';
    this.localidadeService.getBairrosRedeFarmacias({estado: this.filtroRede.estado, municipio: cidade.trim()}).subscribe(
      response => {
        this.arrBairros = response;
        this.textoCarregandoBairro.msg = 'Selecione o Bairro';
        this.conteudoCarregadoBairro = true;
      }
    );
  }

  getMunicipios(cuf: any): void {
    this.arrMunicipios = [];
    this.arrBairros = [];
    this.filtroRede.municipio = '';
    this.filtroRede.bairro = '';

    this.arrCredenciados = [];
    this.conteudoCarregadoMunicipio = false;
    this.textoCarregandoMunicipio.msg = 'Aguarde Carregando dados...';
      this.localidadeService.getMunicipiosRedeFarmacias({estado: cuf}).subscribe(
        response => {
          this.arrMunicipios = response;
          this.arrBairros = [];
          this.textoCarregandoMunicipio.msg = 'Selecione o Municipio';
          this.conteudoCarregadoMunicipio = true;
        }
      );
  }

  filtrarConsultasPendentes(tipo: any): any {
    let me = this;

    return me.arrPendentesConsultas.filter( (itens) => {
      if (tipo == 'N') { // normal
        return itens.CodigoSaudeAgendaTipo != 6
      } else if (tipo == 'S') { // session
        return itens.CodigoSaudeAgendaTipo == 6
      }
    });
  }

  checarPagamentoSessao(sessao, item, evt): void {
    let check: any = evt.target.checked;
    let valorCalculado: any = Number(this.arrConsultasSessoes[sessao].itens[item].valorConsulta);
    valorCalculado = valorCalculado.toFixed(2);
    if (check) {
      this.arrConsultasSessoes[sessao].valorPagar = (Number(valorCalculado) + Number(this.arrConsultasSessoes[sessao].valorPagar)).toFixed(2);
    } else {
      this.arrConsultasSessoes[sessao].valorPagar =  (Number(this.arrConsultasSessoes[sessao].valorPagar) - Number(valorCalculado)).toFixed(2);
    }
  }

  getDashBoard(): void {
    this.conteudoCarregado = false;
    this.conteudoConsultasPreAgendadas = false;
    this.pacienteService.getDashboard({}).subscribe(
      response => {
        this.arrEstados = response.estados;
        this.objetoUsuario = response.dadosUsuario;
        this.arrPendentesConsultas = response.consultasPendentes;
        this.arrConfirmacaoConsultas = response.confirmacaoConsultas;
        this.valorSaldo = response.saldoAtual;
        let compras = response.ultimasComprasCredito;
        let ultimas = response.ultimasConsultas;
        let realizadas = response.consultasRealizadas;
        let consultaSessao = [];

        this.arrPendentesConsultas.map( (itens) => {
          if ( consultaSessao.length == 0 ) {
            if (itens.CodigoSaudeAgendaTipo == 6) {
              consultaSessao.push({
                Clinica: itens.PessoaJuridicaRazaoSocial,
                Doutor : itens.PessoaFisicaNome,
                Especialidade: itens.SaudeEspecialidadeNome,
                CodigoEspecialidade: itens.CodigoEspecialidade,
                CodigoPessoaProfissional: itens.CodigoPessoaProfissional,
                valorPagar: 0,
                itens: []
              });
            }
          }
        });

        let achou = false;

        for (let kk = 0; kk < this.arrPendentesConsultas.length; kk++) {
          achou = false;

          for (let vv = 0; vv < consultaSessao.length; vv++) {
            if (this.arrPendentesConsultas[kk].CodigoPessoaProfissional == consultaSessao[vv].CodigoPessoaProfissional && this.arrPendentesConsultas[kk].CodigoSaudeAgendaTipo == 6) {
              this.arrPendentesConsultas[kk].checado = false;
              consultaSessao[vv].itens.push(this.arrPendentesConsultas[kk]);
              achou = true;
              break;
            }
          }

          if (achou == false) {
            if (this.arrPendentesConsultas[kk].CodigoSaudeAgendaTipo == 6) {
              this.arrPendentesConsultas[kk].checado = false;
              consultaSessao.push({
                Clinica: this.arrPendentesConsultas[kk].PessoaJuridicaRazaoSocial,
                Doutor : this.arrPendentesConsultas[kk].PessoaFisicaNome,
                Especialidade: this.arrPendentesConsultas[kk].SaudeEspecialidadeNome,
                CodigoEspecialidade: this.arrPendentesConsultas[kk].CodigoEspecialidade,
                CodigoPessoaProfissional: this.arrPendentesConsultas[kk].CodigoPessoaProfissional,
                valorPagar: 0,
                itens: this.arrPendentesConsultas[kk]
              });
            }

          }
        }

        this.arrConsultasSessoes = consultaSessao;

        if (this.arrUltimasConsultas.length == 0) {
          for (let i = 0; i < Math.ceil(ultimas.length / 4); i++) {
            let consultas = [];

            for (let j = i * 4; j < (i + 1 < Math.ceil(ultimas.length / 4) ? (i * 4) + 4 : ultimas.length); j++) {
              consultas.push({
                PessoaFisicaNome: ultimas[j].PessoaFisicaNome,
                NomeEstabelecimento: ultimas[j].NomeEstabelecimento,
                SaudeEspecialidadeNome: ultimas[j].SaudeEspecialidadeNome,
                DataAgendado: ultimas[j].DataAgendado,
                CodigoStatus: ultimas[j].CodigoStatus
              })
            }

            this.arrUltimasConsultas.push({
              consultas: consultas
            });
          }
        }

        if (this.arrConsultasRealizadas.length == 0) {
          for (let i = 0; i < Math.ceil(realizadas.length / 4); i++) {
            let consultas = [];

            for (let j = i * 4; j < (i + 1 < Math.ceil(realizadas.length / 4) ? (i * 4) + 4 : realizadas.length); j++) {
              consultas.push({
                PessoaFisicaNome: realizadas[j].PessoaFisicaNome,
                NomeEstabelecimento: realizadas[j].NomeEstabelecimento,
                SaudeEspecialidadeNome: realizadas[j].SaudeEspecialidadeNome,
                DataAgendado: realizadas[j].DataAgendado,
                CodigoStatus: realizadas[j].CodigoStatus
              })
            }

            this.arrConsultasRealizadas.push({
              consultas: consultas
            });
          }
        }

        if (this.arrUltimasCompras.length == 0) {
          for (let i = 0; i < Math.ceil(compras.length / 4); i++) {
            let consultas = [];

            for (let j = i * 4; j < (i + 1 < Math.ceil(compras.length / 4) ? (i * 4) + 4 : compras.length); j++) {
              consultas.push({
                Valor: compras[j].nvalor,
                Data: compras[j].Data,
                StatusTrans: compras[j].statustrans,
                Status: compras[j].status
              })
            }

            this.arrUltimasCompras.push({
              consultas: consultas
            });
          }
        }

        let me = this;

        setTimeout(function () {
          $(me.commentscarousel.nativeElement).owlCarousel({
            singleItem: true,
            navigation: true,
            autoWidth: false,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            pagination: false,
            slideSpeed: 200,
            paginationSpeed: 200,
            rewindSpeed: 200,
            transitionStyle: 'fade',
          });

          $(me.commentscarousel2.nativeElement).owlCarousel({
            singleItem: true,
            navigation: true,
            autoWidth: false,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            pagination: false,
            slideSpeed: 200,
            paginationSpeed: 200,
            rewindSpeed: 200,
            transitionStyle: 'fade',
          });

          $(me.commentscarousel3.nativeElement).owlCarousel({
            singleItem: true,
            navigation: true,
            autoWidth: false,
            navigationText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            pagination: false,
            slideSpeed: 200,
            paginationSpeed: 200,
            rewindSpeed: 200,
            transitionStyle: 'fade',
          });

          $(me.accordion.nativeElement).find('.wpb_accordion_wrapper').accordion({
            header: '> div > h3',
            autoHeight: false,
            heightStyle: 'content',
            active: 0,
            collapsible: true,
            navigation: true,
            change: function (event, ui) {
              if ($.fn.isotope != undefined) {
                ui.newContent.find('.isotope').isotope('reLayout');
              }
            }
          });
        }, 1);

        this.conteudoCarregado = true;
        this.conteudoConsultasPreAgendadas = true;
      }
    );
  }

  getEspecialidades(): void {
    this.arrEspecialidades = [];
    this.conteudoCarregado = false;
    this.medicoService.getEspecialidades({}).subscribe(
      response => {
        this.arrEspecialidades = response;
        this.executarAgendamento();
      }
    );
  }

  confirmarAgendamento(agendamento): any {
    let me = this;
    me.userService.setPrepararPagamento(agendamento);
    me.router.navigate(['pagarpersonal']);
  }

  confirmarAgendamentoSessao(agendamento): any {
    let me = this;
    let count = 0;
    for ( let x = 0; x < agendamento.itens.length; x++ ) {
      if (agendamento.itens[x].checado) {
        count ++;
      }
    }
    if ( count !=  agendamento.itens.length) {
      let previousWindowKeyDown = window.onkeydown;
      swal({
        title: ' Confirmar Sessões ?',
        text: 'Confirma o pagamento de ' + count + ' Sessões selecionadas? ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Pagar!',
        closeOnConfirm: true,
        showLoaderOnConfirm: false
      }, function () {
        this.conteudoCarregadoPagamento = false;
        window.onkeydown = previousWindowKeyDown;
        me.userService.setPrepararPagamentoSessao(agendamento);
        me.router.navigate(['pagarpersonal']);
      });
    } else {
      me.userService.setPrepararPagamentoSessao(agendamento);
      me.router.navigate(['pagarpersonal']);
    }

  }

  executarAgendamento(): void {
    let objetoAgendamento: any = this.userService.getAgendamento();
    if (objetoAgendamento !== undefined) {
      this.agendamentoService.executarAgendamento(objetoAgendamento).subscribe(
        response => {
          this.userService.setAgendamento(undefined);

          this.getDashBoard();
        }
      );
    } else {
      this.getDashBoard();
    }
  }

  ngOnInit(): any {
    this.getEspecialidades();
  }

  confirmarHorario(agendamento): any {
    let me = this;

    let data = agendamento.DataAgendado.substring(8, 10) + '/' +
      agendamento.DataAgendado.substring(5, 7) + '/' +
      agendamento.DataAgendado.substring(0, 4);

    let previousWindowKeyDown = window.onkeydown;
    swal({
      title: 'Confirmar Consulta',
      text: 'Confirmar a consulta as ' + agendamento.Horario + 'h no dia ' + data + '?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, confirmar!',
      cancelButtonText: 'Não, cancelar.',
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, function () {
      this.conteudoCarregadoPagamento = false;
      window.onkeydown = previousWindowKeyDown;

      me.agendamentoService.alterarStatusConsulta({CodigoAgenda: agendamento.CodigoAgenda, status: 2}).subscribe(
        response => {
          swal({
            title: 'Confirmar Consulta',
            text: 'Consulta confirmada com sucesso!',
            type: 'success',
            showCancelButton: false,
            closeOnConfirm: true
          }, function () {
            me.arrConfirmacaoConsultas = [];
            me.getEspecialidades();
          });
        }
      );
    });
  }

  recusarHorario(agendamento): any {
    let me = this;

    let data = agendamento.DataAgendado.substring(8, 10) + '/' +
      agendamento.DataAgendado.substring(5, 7) + '/' +
      agendamento.DataAgendado.substring(0, 4);

    let previousWindowKeyDown = window.onkeydown;
    swal({
      title: 'Recusar Consulta',
      text: 'Recusar a consulta as ' + agendamento.Horario + 'h no dia ' + data + '?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, recusar!',
      cancelButtonText: 'Não.',
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, function () {
      this.conteudoCarregadoPagamento = false;
      window.onkeydown = previousWindowKeyDown;

      me.agendamentoService.alterarStatusConsulta({CodigoAgenda: agendamento.CodigoAgenda, status: 7}).subscribe(
        response => {
          swal({
            title: 'Recusar Consulta',
            text: 'Consulta recusada com sucesso!',
            type: 'success',
            showCancelButton: false,
            closeOnConfirm: true
          }, function () {
            me.arrConfirmacaoConsultas = [];
            me.getEspecialidades();
          });
        }
      );
    });
  }

}
