import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.html',
  styleUrls: ['./login.css']
})

export class LoginComponent implements OnInit {
  usuario: any = {
    usuario: '',
    senha: ''
  };

  mostraMsg = false;

  @ViewChild('msgError', {static: false}) msgError: ElementRef;

  constructor(private router: Router, private userService: UserService) {
  }

  ngOnInit() {
    let objetoAgendamento: any = this.userService.getAgendamento();
    if (objetoAgendamento !== undefined) {
      this.mostraMsg = true;
    }
  }

  logarSite(usuario: any): any {
    if (usuario.usuario != '' && usuario.senha != '') {
      this.userService.login(usuario, false).subscribe(
        response => {
          if (response.success) {
            this.router.navigate(['dashboard']);
          } else {
            $(this.msgError.nativeElement).show();
          }
        });
    } else {
      $(this.msgError.nativeElement).show();
    }
  }

  cadastrar(): any {
    this.router.navigate(['cadastro']);
  }
}
