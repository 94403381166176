import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {ServiceMain} from './service-main.service';
import {HttpClient} from '@angular/common/http';
import {PersistenceService} from 'angular-persistence';

@Injectable()
export class PostmonCorreiosService {
  // Correios
  constructor(private _correios: HttpClient) {

  }

  getCepCorreios(cep: string): Observable<any> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this._correios.get('http://api.postmon.com.br/v1/cep/' + cep
    ).map(res => {
      return res;
    }).map((res) => {
      return res;
    });
  }

  getGeoCode(address: any) {
    const ApiKey = 'AIzaSyAZp6ZgM5N03GZYeCGQqQdHS_11MFoJC6I';

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return this._correios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + ApiKey
    ).map(res => {
      return res;
    }).map((res) => {
      return res;
    });
  }
}
