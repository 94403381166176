import {Component} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.html',
  styleUrls: ['footer.css']
})
export class FooterComponent {

  goScroll(link): void {
    try {
      document.getElementById(link).scrollIntoView();

      let scrolledY = window.scrollY;

      if (scrolledY) {
        window.scroll(0, scrolledY - 93);
      }
    } catch (ss) {
    }
  }

  goTop(): void {
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 1);
  }

}
