import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, retry} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {PersistenceService, StorageType} from 'angular-persistence';

@Injectable()
export class UserService {
  private id: string;
  private loginn: string;
  private nome: string;

  private perfil: string;
  private status: string;
  private email: string;
  private token: string;
  private agendamento: any;
  private prepararPagamento: any;
  private prepararSessao: any;
  logado = false;

  constructor(private _login: HttpClient,
              private persistenceService: PersistenceService) {
  }

  getUser(): any {
    return {
      id: this.id,
      login: this.loginn,
      nome: this.nome,
      perfil: this.perfil,
      status: this.status
    };
  }

  setPrepararPagamentoSessao(agenda): void {
    this.prepararSessao = agenda;
  };

  getPreparaPagamentoSessao(): any {
    return this.prepararSessao;
  }

  setPrepararPagamento(agenda): void {
    this.prepararPagamento = agenda;
  };

  getPreparaPagamento(): any {
    return this.prepararPagamento;
  }

  setAgendamento(agendamento: any): void {
    this.agendamento = agendamento;
  }

  getAgendamento(): any {
    return this.agendamento;
  }

  login(login: any, lembrarUsuario: boolean): Observable<any> {
    let body = JSON.stringify(login);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._login.post(environment.serverUrl + 'site/autenticacao', body,
      options
    ).pipe(
      retry(3),
      catchError(this.handleError),
      map((res: any) => {
        if (res.success) {
          this.id = res.usuario.CodigoPessoa.trim();
          this.loginn = res.usuario.Cpf.trim();
          this.nome = res.usuario.PessoaFisicaNome.trim();
          this.email = res.usuario.email.trim();

          this.token = res.token;
          this.persistenceService.set('token', res.token, {type: StorageType.LOCAL});

          this.logado = true;

          }

        return res;
      })
    );
  }

  trocarSenhaSite(login: any, lembrarUsuario: boolean): Observable<any> {
    let body = JSON.stringify(login);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._login.post(environment.serverUrl + 'site/trocarsenha', body,
      options
    ).pipe(
      retry(3),
      catchError(this.handleError),
      map((res: any) => {
        return res;
      })
    );
  }

  logout(): void {
    let login = this.persistenceService.get('login', StorageType.LOCAL);
    this.persistenceService.removeAll(StorageType.LOCAL);
    this.persistenceService.set('login', login, {type: StorageType.LOCAL});
    this.logado = false;
  }

  logoutSesi(): void {
    this.persistenceService.removeAll(StorageType.LOCAL);
  }

  checkTipoSistema(): any {
    return this.persistenceService.get('tipoSistema', StorageType.LOCAL);
  }

  validarLogin(fallback, loginreturn, login): Observable<any> {
    let body = JSON.stringify(login);
    let tokenCheck = this.persistenceService.get('token', StorageType.LOCAL);
    if (!tokenCheck) {
      return Observable.of(true);
    }
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token-Update': this.persistenceService.get('token', StorageType.LOCAL)
      })
    };

    return this._login.get(environment.serverUrl + 'site/validartoken',
      options
    ).pipe(
      retry(3),
      catchError( () => {
        this.logado = false;
        fallback();
        return Observable.of(login);
      }),
      map((res: any) => {

        if (res.success) {
          this.id = res.usuario.CodigoPessoa.trim();
          this.loginn = res.usuario.Cpf.trim();
          this.nome = res.usuario.PessoaFisicaNome.trim();
          this.email = res.usuario.email.trim();

          this.token = res.token;

          this.logado = true;
          this.persistenceService.set('token', res.token, {type: StorageType.LOCAL});
          loginreturn();
          return true;

        }

        return false;
      })
    );
  }

  protected handleError(error: any): any {
    swal('Ocorreu um problema!', error._body + ' - Por favor, entre em contato com o administrador.', 'error');
    return of();
  }
}
