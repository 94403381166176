import {Injectable} from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {PersistenceService, StorageType} from 'angular-persistence';
import {UserService} from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private userService: UserService,
              private persistenceService: PersistenceService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return this.userService.validarLogin(() => (this.persistenceService.get('sesi', StorageType.LOCAL) == 'true' ?
      this.router.navigate(['sesi']) : this.router.navigate([''])), () => {
    }, false);
  }

  /*canActivate(route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.validarLogin(() => (this.persistenceService.get('sesi') == 'true' ? this.router.navigate(['sesi']) : this.router.navigate([''])), () => {
    }, false);
  }*/
}
