import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fone'
})
export class FonePipe implements PipeTransform {
  transform(value: string, args: any[]) {
    return '(' + value.substring(0, 2) + ')' +
      value.substring(2);
  }
}
