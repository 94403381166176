import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {PacienteService} from '../services/paciente.service';
import {AgendamentoService} from '../services/agendamento.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {LocalidadeService} from '../services/localidade.service';
import {PostmonCorreiosService} from '../services/postmon.correios.services';

declare let PagSeguroDirectPayment: any;

@Component({
  selector: 'app-recarga-doctorday',
  templateUrl: './recarga.html',
  styleUrls: ['./recarga.css']
})

export class RecargaComponent implements OnInit {
  prepararPagamento: any = {
    PessoaJuridicaRazaoSocial: '',
    PessoaFisicaNome: '',
    DataAgendado: '',
    HorarioInicial: '',
    HorarioFinal: ''
  };

  loadDadosPagamento = false;
  valorSaldo = '0,00';
  arrUltimasConsultas: any = [];
  objetoUsuario: any = {};

  pagamento: any = {
    metodo : '1',
    session: '',
    mes: '',
    ano: '',
    codigoseguranca: '',
    cvv: false,
    hasDueDate : false,
    brand : '',
    identificacaoComprador: '',
    token: '',
    valordeposito: '',
    valordepositotaxaadministrativa: ''
  };

  loadBandeira = true;

  arrEstados: any = [
    {cuf: 'AC'},
    {cuf: 'AL'},
    {cuf: 'AM'},
    {cuf: 'AP'},
    {cuf: 'BA'},
    {cuf: 'CE'},
    {cuf: 'DF'},
    {cuf: 'ES'},
    {cuf: 'GO'},
    {cuf: 'MA'},
    {cuf: 'MG'},
    {cuf: 'MS'},
    {cuf: 'MT'},
    {cuf: 'PA'},
    {cuf: 'PB'},
    {cuf: 'PE'},
    {cuf: 'PI'},
    {cuf: 'PR'},
    {cuf: 'RJ'},
    {cuf: 'RN'},
    {cuf: 'RO'},
    {cuf: 'RR'},
    {cuf: 'RS'},
    {cuf: 'SC'},
    {cuf: 'SE'},
    {cuf: 'SP'},
    {cuf: 'TO'}
  ];

  arrMunicipios: any = [];
  arrBairros: any = [];
  endereco: any;
  dadoscartao: any = {
    numero: '',
    nome: '',
    checado: false
  };
  iconRecarga: any = {
    senderName:     {display: 'red'},
    senderCPF:      {display: 'red'},
    senderAreaCode: {display: 'red'},
    senderPhone:    {display: 'red'},
    senderEmail:    {display: 'red'},
    shippingAddressStreet:     {display: 'red'},
    shippingAddressNumber:     {display: 'red'},
    shippingAddressComplement: {display: 'red'},
    shippingAddressDistrict:   {display: 'red'},
    shippingAddressPostalCode: {display: 'red'},
    shippingAddressCity:    {display: 'red'},
    shippingAddressState:   {display: 'red'},
    creditCardHolderBirthDate: {display: 'red'}
  };
  dadosRecarga: any = {
    senderID:       '',
    senderName:     '',
    senderCPF:      '',
    senderAreaCode: '',
    senderPhone:    '',
    senderEmail:    '',
    shippingAddressStreet:     '',
    shippingAddressNumber:     '',
    shippingAddressComplement: '',
    shippingAddressDistrict:   '',
    shippingAddressPostalCode: '',
    shippingAddressCity:    '',
    shippingAddressState:   '',
    shippingAddressCountry: 'BRA',
    creditCardHolderBirthDate: ''
  };
  dadosCreditCard: any = [];
  valorVerdadeiro = 0.00;
  bandeiraCartao: any = {};
  metodosPagamentoCartao: any = [];
  anoLista: any = [];
  imagemEscolhida = '';
  messageErrorShow = false;
  wizardTitle = 'dados';

  bandeira: any = 1;
  conteudoCarregadoPagamento = true;
  marcacaoTermo = false;

  @ViewChild('modalTermoAceite', {static: false}) modalTermoAceite: ModalDirective;
  @ViewChild('numerocartao', {static: false}) numerocartao: ElementRef;
  @ViewChild('codigoseguranca', {static: false}) codigoseguranca: ElementRef;
  @ViewChild('valorconsulta', {static: false}) valorconsulta: ElementRef;
  @ViewChild('valordepositotaxaadministrativa', {static: false}) valordepositotaxaadministrativa: ElementRef;

  @ViewChild('wizardCardAbout', {static: false}) wizardCardAbout: ElementRef;
  @ViewChild('wizardCardAddress', {static: false}) wizardCardAddress: ElementRef;
  @ViewChild('wizardCardCount', {static: false}) wizardCardCount: ElementRef;

  constructor(private router: Router,
              private userService: UserService,
              private pacienteService: PacienteService,
              private agendamentoService: AgendamentoService,
              private localidadeService: LocalidadeService,
              private correiosService: PostmonCorreiosService) {
  }

  changeBandeira(bandeira): void {
    this.bandeira = bandeira;
  }

  abrirTermoAceite(): void {
    this.modalTermoAceite.show();
  }

  calcularTaxas(val): any {

    let me = this;
    let valorConsultaTaxas: any = String($(me.valorconsulta.nativeElement).val());
    valorConsultaTaxas = valorConsultaTaxas.replace(/\./g, '');
    valorConsultaTaxas = Number( valorConsultaTaxas.replace(',', '.') );
    let valorTaxa = valorConsultaTaxas * 0.04;
    valorConsultaTaxas = ( valorConsultaTaxas + ( valorTaxa ) ) + 0.40;
    this.valorVerdadeiro = valorConsultaTaxas;
    $(me.valordepositotaxaadministrativa.nativeElement).val(valorConsultaTaxas.toFixed(2));
    $('.valoresMonetario').unmask();
    $('.valoresMonetario').mask('000.000.000,00', {reverse: true});
  }

  getParcelamentoPagSeguro(): any {
    let me  = this;
    PagSeguroDirectPayment.getInstallments({
      amount: 0.10,
      maxInstallmentNoInterest: 1,
      brand: me.pagamento.brand,
      success: (response) => {   },
      error: (response) => {   },
      complete: (response) => {   }
    });
  }

  validarFormulario(): any {
    let me = this;

    if ( me.dadosRecarga.senderName == '' ) {
      swal('Erro!', 'O campo Nome, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.senderCPF == '' ) {
      swal('Erro!', 'O campo CPF, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.senderPhone == '' ) {
      swal('Erro!', 'O campo Telefone, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.senderEmail == '' ) {
      swal('Erro!', 'O campo E-mail, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.creditCardHolderBirthDate == '' ) {
      swal('Erro!', 'O campo Data de Aniversario, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.shippingAddressPostalCode == '' ) {
      swal('Erro!', 'O campo CEP, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.shippingAddressState == '' ) {
      swal('Erro!', 'O campo Estado, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.shippingAddressCity == '' ) {
      swal('Erro!', 'O campo Cidade, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.shippingAddressDistrict == '' ) {
      swal('Erro!', 'O campo Bairro, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.shippingAddressStreet == '' ) {
      swal('Erro!', 'O campo Logradouro, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.shippingAddressNumber == '' ) {
      swal('Erro!', 'O campo Numero, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.dadosRecarga.shippingAddressComplement == '' ) {
      swal('Erro!', 'O campo Complemento, não pode estar vazio', 'error');
      me.mudarestado(0);
      return false;
    } else if ( me.pagamento.numero == '' ) {
      swal('Erro!', 'O campo Número do Cartão, não pode estar vazio', 'error');
      me.mudarestado(1);
      return false;
    } else if ( me.pagamento.hasDueDate ) {
      if ( me.pagamento.mes == '' ) {
        swal('Erro!', 'O campo Mês do Cartão, não pode estar vazio', 'error');
        me.mudarestado(1);
        return false;
      } else if ( me.pagamento.mes == '' ) {
        swal('Erro!', 'O campo Ano do Cartão, não pode estar vazio', 'error');
        me.mudarestado(1);
        return false;
      }
    } else if ( me.pagamento.cvv ) {
      if ( me.pagamento.codigoseguranca == '' ) {
        swal('Erro!', 'O campo Código de segurança do Cartão, não pode estar vazio', 'error');
        me.mudarestado(1);
        return false;
      }
    } else if ( Number(me.pagamento.valordeposito) <= 0 ) {
      swal('Erro!', 'O campo Valor não pode ser em branco, não pode estar vazio', 'error');
      me.mudarestado(1);
      return false;
    }


    me.getTokenPagseguro();

  }

  efetuarPagamentoPagSeguro(): any {
    let me = this;
    let pagamento = me.pagamento;
    pagamento.valor = $(me.valorconsulta.nativeElement).val();
    pagamento.dadosPagamento = me.prepararPagamento || {};
    pagamento.dadosRecarga = me.dadosRecarga;
    me.loadDadosPagamento = true;


    // me.getParcelamentoPagSeguro();
    me.agendamentoService.pagseguroEfetuarPagamento(pagamento).subscribe(
      response => {
        if (response.success) {
          swal('Transação feita', 'Estamos aguardado dados da operadora de cartão. Fique atento ao seu e-mail para confirmação', 'success');
          me.router.navigate(['dashboard']);
        } else {
          swal('Erro!', response.message, 'error');
          me.router.navigate(['dashboard']);
        }

        me.loadDadosPagamento = false;
      },
      error => {
        if (error.status == 500) {
          swal({
            title: 'Erro',
            text: 'Não foi possivel preparar o pagamento',
            type: 'error'
          }, function () {

          });
        }
        me.loadDadosPagamento = false;
      }
    );
  }

  getTokenPagseguro(): any {

    let me = this;
    let parameters: any = {};
    parameters.cardNumber = me.pagamento.numero;
    if ( me.pagamento.hasDueDate ) {
      parameters.expirationMonth = me.pagamento.mes;
      parameters.expirationYear = me.pagamento.ano;
    }
    if ( me.pagamento.cvv ) {
      parameters.cvv = me.pagamento.codigoseguranca;
    }
    parameters.brand = me.pagamento.brand;

    let payment = {
      ...parameters,
      success: (response) => {
        me.pagamento.token = response.card.token;

        me.efetuarPagamentoPagSeguro();
      },
      error: (response) => { swal('Problema com os dados do Cartão', 'Revise todos os dados do cartão', 'error');  },
      complete: (response) => {      }
    };

    PagSeguroDirectPayment.createCardToken(payment);
  }

  verificaBandeira(cartao): any {
    let me = this;
    if ( cartao.length == 6 || cartao.length == 16) {
      me.loadBandeira = false;
      PagSeguroDirectPayment.getBrand({
        cardBin: cartao,
        success: function(response) {
          me.bandeiraCartao = response;
          let obj = me.metodosPagamentoCartao.paymentMethods.CREDIT_CARD.options;
          for (let key in obj ) {
            if (obj[key].name.toLowerCase() == me.bandeiraCartao.brand.name.toLowerCase()) {
              if (obj[key].name.toLowerCase() == 'personalcard') {
                me.loadBandeira = true;
                swal('Atenção', 'Não é permitida a carga no cartão Virtual através do cartão Personal Card. Por favor utilize outra Bandeira de cartão de crédito  ', 'warning');
                return;
              }
              me.imagemEscolhida = 'assets/bandeiras_cartoes/' + obj[key].name.toLowerCase() + '.png';
              me.pagamento.brand = me.bandeiraCartao.brand.name;
              if ( me.bandeiraCartao.brand.config.hasCvv ) {
                me.pagamento.cvv = true;
              } else {
                me.pagamento.cvv = false;
                me.pagamento.codigoseguranca = '';
              }

              if ( me.bandeiraCartao.brand.config.hasDueDate ) {
                me.pagamento.hasDueDate = true;
              } else {
                me.pagamento.hasDueDate = false;
              }
              me.pagamento.mes = '';
              me.pagamento.ano = '';
            }
          }
          me.loadBandeira = true;
        },
        error: function(response) {

        },
        complete: function(response) {

        }
      });
    }
  }

  getDataAno(): any {
    let dataAtual = new Date();

    let anoTemp = dataAtual.getFullYear();
    this.pagamento.ano = anoTemp;
    for ( let x = 0; x < 30; x++ ) {
      this.anoLista.push(anoTemp);
      anoTemp++;
    }

  }

  getMetodoPagamentoPagSeguro(): any {
    let me = this;
    me.loadDadosPagamento = true;

    PagSeguroDirectPayment.getPaymentMethods({
      amount: '', // {valor da transação}
      success: (response) => {
        me.metodosPagamentoCartao = response;
        me.getDataAno();
        me.loadDadosPagamento = false;
      } , // {função de callback para chamadas bem sucedidas}
      error: (response) => {
        swal('Erro!', 'No momento, alguns serviços estão indisponíveis. Tente novamente em alguns minutos', 'warning');
        }, // {função de callback para chamadas que falharam}
      complete: (response) => {
        } // {função de callback para todas chamadas}
    });

  }

  pagseguroGetSession(): void {
    let me = this;
    me.loadDadosPagamento = true;
    me.agendamentoService.pagseguroGetSession({}).subscribe(
      response => {
        if (response.success) {

          me.pagamento.session = response.session.id;
          PagSeguroDirectPayment.setSessionId(me.pagamento.session);
          me.pagamento.identificacaoComprador = PagSeguroDirectPayment.getSenderHash();
          if ( me.pagamento.identificacaoComprador ) {
            me.getMetodoPagamentoPagSeguro();
          } else {
            swal({
              title: 'Metodo de Pagamento temporariamente fora do ar',
              text: 'No momento, alguns serviços estão indisponíveis. Tente novamente em alguns minutos',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Voltar!'
            }, function () {
              me.router.navigate(['dashboard']);
              me.loadDadosPagamento = false;
            });

          }

        } else {
          swal('Erro!', response.message, 'error');
          me.loadDadosPagamento = false;
        }
      },
      error => {
        if (error.status == 500) {
          swal({
            title: 'Erro',
            text: 'Não foi possivel preparar o pagamento',
            type: 'error'
          }, function () {
            me.loadDadosPagamento = false;
          });
        }
      }
    );

  }

  pagarPersonal(pagar: any): any {
    let me = this;

    if (pagar.numero === '') {
      swal('Campo vazio', 'digite o numero do cartão por favor', 'error');
      return false;
    } else if (pagar.nome === '') {
      swal('Campo vazio', 'digite o Nome Impresso no Cartão', 'error');
      return false;
    } else if (pagar.checado == false) {
      swal('Termo de Aceite', 'Por favor , Marque que concorda com o termo de aceite.', 'error');
      return false;
    }

    let filtro: any = me.userService.getPreparaPagamento();
    filtro.cartaonumero = pagar.numero;
    filtro.nomeimpresso = pagar.nome;

    swal({
      title: 'Finalizar pagamento ?',
      text: 'Deseja realmente finalizar esse pagamento ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, Pagar!',
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, function () {
      this.conteudoCarregadoPagamento = false;
      me.agendamentoService.executarPagamento(filtro).subscribe(
        response => {
          if (response.success) {
            swal({
              title: 'Consulta paga com sucesso',
              text: 'Sua consulta já foi paga em instantes você receberá a confirmação por e-mail',
              type: 'success'
            }, function () {
              me.conteudoCarregadoPagamento = true;
              me.router.navigate(['dashboard']);
            });
          } else {
            swal('Erro!', response.message, 'error');

          }
          me.conteudoCarregadoPagamento = true;
        },
        error => {
          if (error.status == 500) {
            swal({
              title: 'Erro',
              text: 'Não foi possivel realizar o pagamento',
              type: 'error'
            }, function () {
              me.conteudoCarregadoPagamento = true;
            });
          }
        }
      );
    });
  }

  getDashBoard(): void {
    this.pacienteService.getDashboard({}).subscribe(
      response => {
        this.objetoUsuario = response.dadosUsuario;
        this.dadosRecarga.senderID = this.objetoUsuario.CodigoPessoa;
        this.dadosRecarga.senderName  = this.objetoUsuario.PessoaFisicaNome;
        let cpfTemp = this.objetoUsuario.Cpf;
        this.objetoUsuario.Cpf =  cpfTemp.substring(0, 3) + '.' + cpfTemp.substring(3, 6) + '.' + cpfTemp.substring(6, 9) + '-' + cpfTemp.substring(9);
        this.dadosRecarga.senderCPF   = this.objetoUsuario.Cpf;
        this.dadosRecarga.senderEmail = this.objetoUsuario.email;
        this.dadosRecarga.senderPhone = this.objetoUsuario.telefone;
        let date = this.objetoUsuario.dtnascimento;
        this.dadosRecarga.creditCardHolderBirthDate = date.substring(8, 10) + date.substring(5, 7) + date.substring(0, 4);
        this.dadosRecarga.shippingAddressState = this.objetoUsuario.cuf.trim();
        this.getMunicipios(this.objetoUsuario.cuf.trim(), false);
        this.dadosRecarga.shippingAddressCity = this.objetoUsuario.cnomemun.trim();
        this.dadosRecarga.shippingAddressDistrict = this.objetoUsuario.cnomebai;
        this.dadosRecarga.shippingAddressNumber = this.objetoUsuario.numero;
        this.dadosRecarga.shippingAddressComplement = this.objetoUsuario.complemento;

        this.dadosRecarga.shippingAddressStreet = this.objetoUsuario.cnomelog;
        this.dadosRecarga.shippingAddressStreet.trim();
        if (this.dadosRecarga.shippingAddressStreet == ' ') {
          this.dadosRecarga.shippingAddressStreet = '';
        }
        this.dadosRecarga.shippingAddressPostalCode = this.objetoUsuario.cep.trim();
        this.dadosRecarga.shippingAddressCity = this.objetoUsuario.cnomemun.trim();
        this.dadosRecarga.shippingAddressCountry = this.objetoUsuario.cnomebai.trim();
        this.dadosRecarga.shippingAddressState = this.objetoUsuario.cuf.trim();

        this.getCreditCardPaciente(this.dadosRecarga);
      }
    );
  }

  getCreditCardPaciente(dadosRecarga): void {
    let $this = this;
    this.pacienteService.getCreditCardPaciente(dadosRecarga)
      .subscribe(
        response => {
          if (response.success) {
            $this.dadosCreditCard = response.PacienteCreditCard;
          }
      });
  }

  ngOnInit(): any {
    let me = this;

    if (me.userService.getPreparaPagamento()) {
      me.prepararPagamento = me.userService.getPreparaPagamento();
      me.prepararPagamento.valorMedico = parseFloat(me.prepararPagamento.valorMedico);
    }

    me.getDashBoard();
    me.pagseguroGetSession();
    setTimeout(function () {
      $('.valoresNumeroCartao').mask('00000000000000000');
      $('.valoresMonetario').mask('000.000.000,00', {reverse: true});
      $('.codigoSeguranca').mask('000000');
      $('.valoresCPF').mask('000.000.000-00');
      $('.valoresFone').mask('(00) 0000-00000');
      $('.valoresCEP').mask('00000-000')
      $('.dataNascimento').mask('00/00/0000');
    }, 1);
  }

  mudarestado(status: number): any {
    let me = this;

    switch (status) {
      case 0:
        if ( me.pagamento.numero == '' ) {
          swal('Erro!', 'O campo Número do Cartão, não pode estar vazio', 'error');
          return false;
        } else if ( me.pagamento.hasDueDate ) {
          if ( me.pagamento.mes == '' ) {
            swal('Erro!', 'O campo Mês do Cartão, não pode estar vazio', 'error');
            return false;
          } else if ( me.pagamento.mes == '' ) {
            swal('Erro!', 'O campo Ano do Cartão, não pode estar vazio', 'error');
            return false;
          }
        } else if ( me.pagamento.cvv ) {
          if ( me.pagamento.codigoseguranca == '' ) {
            swal('Erro!', 'O campo Código de segurança do Cartão, não pode estar vazio', 'error');
            return false;
          }
        } else if ( Number(me.pagamento.valordeposito) <= 0 ) {
          swal('Erro!', 'O campo Valor não pode ser em branco, não pode estar vazio', 'error');
          return false;
        }

        $(this.wizardCardAbout.nativeElement).css({display: 'none' });
        $(this.wizardCardCount.nativeElement).css({display: 'none' });

        $(this.wizardCardAbout.nativeElement).css({display: 'block' });
        this.wizardTitle = 'dados';
        this.validarWizardCard(status);
        break;
      case 1:
        if ( me.dadosRecarga.senderName == '' ) {
          swal('Erro!', 'O campo Nome, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.senderCPF == '' ) {
          swal('Erro!', 'O campo CPF, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.senderPhone == '' ) {
          swal('Erro!', 'O campo Telefone, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.senderEmail == '' ) {
          swal('Erro!', 'O campo E-mail, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.creditCardHolderBirthDate == '' ) {
          swal('Erro!', 'O campo Data de Aniversario, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.shippingAddressPostalCode == '' ) {
          swal('Erro!', 'O campo CEP, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.shippingAddressState == '' ) {
          swal('Erro!', 'O campo Estado, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.shippingAddressCity == '' ) {
          swal('Erro!', 'O campo Cidade, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.shippingAddressDistrict == '' ) {
          swal('Erro!', 'O campo Bairro, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.shippingAddressStreet == '' ) {
          swal('Erro!', 'O campo Logradouro, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.shippingAddressNumber == '' ) {
          swal('Erro!', 'O campo Numero, não pode estar vazio', 'error');
          return false;
        } else if ( me.dadosRecarga.shippingAddressComplement == '' ) {
          swal('Erro!', 'O campo Complemento, não pode estar vazio', 'error');
          return false;
        }

        $(this.wizardCardAbout.nativeElement).css({display: 'none' });
        $(this.wizardCardCount.nativeElement).css({display: 'none' });

        $(this.wizardCardCount.nativeElement).css({display: 'block' });
        this.wizardTitle = 'dados do cartão';
        this.validarWizardCard(status);
        break;
    }
  }

  validarWizardCard(status: any): void {

    this.messageErrorShow = false;

  }

  private wpb_prepare_tab_content(event, ui) {
    let panel = ui.panel || ui.newPanel;

    let $ui_panel = jQuery(panel).find('.isotope'),
      $google_maps = jQuery(panel).find('.wpb_gmaps_widget');
    if ($ui_panel.length > 0) {
      $ui_panel.isotope('reLayout');
    }

    if ($google_maps.length && !$google_maps.is('.map_ready')) {
      let $frame = $google_maps.find('iframe');
      $frame.attr('src', $frame.attr('src'));
      $google_maps.addClass('map_ready');
    }
  }

  pesquisarCepCorreios(cep: string): void {
    if ( cep.length == 9) {
      this.buscaCepCorreios(cep);
    }

  }

  buscaCepCorreios(cepCorreios: string): void {
    cepCorreios = cepCorreios.replace('-', '');
    let cepLimpo = '';
    if (cepCorreios.length > 8) {
      cepLimpo = cepCorreios.substring(0, 2) + cepCorreios.substring(3, 6) + cepCorreios.substring(7);
    } else {
      cepLimpo = cepCorreios;
    }

    if (cepLimpo == '') {
      swal({
        title: 'CEP Não Informado!',
        text: 'Desculpa, mais você deve informar um CEP válido.',
        type: 'error',
        closeOnConfirm: true
      });

    } else {
      this.correiosService.getCepCorreios(cepLimpo)
        .subscribe(
          response => {
            this.endereco = {
              cep: response.cep,
              estado: response.estado,
              municipio: response.cidade,
              bairro: (response.bairro) ? response.bairro : '',
              logradourotipo: '',
              logradouro: (response.logradouro) ? response.logradouro : '',
              numero: '',
              complemento: response.complemento,
              codigoIbge: response.cidade_info.codigo_ibge
            };


            this.dadosRecarga.shippingAddressPostalCode = this.endereco.cep;
            this.dadosRecarga.shippingAddressState = this.endereco.estado;
            this.dadosRecarga.shippingAddressDistrict = this.endereco.bairro;
            this.dadosRecarga.shippingAddressStreet = this.endereco.logradouro;
            this.dadosRecarga.shippingAddressComplement = this.endereco.complemento;

            this.dadosRecarga.shippingAddressCity = '';

            this.localidadeService.getSolicitarCep({cep: this.endereco.cep}).subscribe(
              responseCity => {
                if (responseCity.length > 0) {
                  this.getMunicipios(this.endereco.estado, true);
                  this.dadosRecarga.shippingAddressCity = responseCity[0].municipio;
                }
              }
            );
          }
        );

    }
  }

  getBairros(cidade: any, pri: boolean): void {
    this.arrBairros = [];
    this.localidadeService.getBairros({municipio: cidade}).subscribe(
      response => {
        this.arrBairros = response;
      }
    );
  }

  getMunicipios(cuf: any, pri: boolean): void {
    this.arrMunicipios = [];
    this.arrBairros = [];
    if (!pri) {
      this.dadosRecarga.shippingAddressDistrict = '';
    }

    this.localidadeService.getMunicipios({estado: cuf}).subscribe(
      response => {
        this.arrMunicipios = response;
        this.arrBairros = [];
        if (pri) {
          this.getBairros(this.dadosRecarga.shippingAddressCity, pri);
        }
      }
    );
  }

  utilizarEste(cc: any): void {
    this.pagamento.numero = cc.CreditCardNumero;
    this.pagamento.mes =  parseInt(cc.CreditCardMes, 10);
    this.pagamento.ano = cc.CreditCardAno;
  }
}
