import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {PacienteService} from '../services/paciente.service';
import {AgendamentoService} from '../services/agendamento.service';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pagar-personal',
  templateUrl: './personal.html',
  styleUrls: ['./personal.css']
})

export class PagarPersonalComponent implements OnInit {
  prepararPagamento: any = {
    PessoaJuridicaRazaoSocial: '',
    PessoaFisicaNome: '',
    DataAgendado: '',
    HorarioInicial: '',
    HorarioFinal: ''
  };

  arrPrepararPagamento: any = [];
  loadDadosPagamento = false;

  arrUltimasConsultas: any = [];
  objetoUsuario: any = {};

  pagamento: any = {
      metodo : '1',
      session: '',
      mes: '',
      ano: '',
      codigoseguranca: '',
      cvv: false,
      hasDueDate : false,
      brand : '',
      identificacaoComprador: '',
      token: '',
      valordeposito: '0'
  };

  loadBandeira = true;

  dadoscartao: any = {
    numero: '',
    nome: '',
    checado: false
  };

  dadoscartaoDoctorDay: any = {
    nome: '',
    checado: false
  };

  anoLista: any = [];
  imagemEscolhida = '';

  conteudoCarregadoPagamento = true;
  marcacaoTermo = false;

  @ViewChild('modalTermoAceite', {static: false}) modalTermoAceite: ModalDirective;
  @ViewChild('numerocartao', {static: false}) numerocartao: ElementRef;
  @ViewChild('codigoseguranca', {static: false}) codigoseguranca: ElementRef;
  @ViewChild('valorconsulta', {static: false}) valorconsulta: ElementRef;

  constructor(private router: Router,
              private userService: UserService,
              private pacienteService: PacienteService,
              private agendamentoService: AgendamentoService) {
  }

  selecionarMetodoPagamento(metodo): any {

    let me = this;
    me.pagamento.metodo = metodo;
    if ( metodo === '2' ) {

    } else if ( metodo === '1' ) {
      if (me.pagamento.session !== '') {
        me.pagamento.session = '';
      }
    }
  };

  pagarDoctorDay(): any {
    let me = this;

    if ( me.dadoscartao.checado == 1) {
      let filtro: any = me.userService.getPreparaPagamento();

      swal({
        title: 'Finalizar pagamento ?',
        text: 'Deseja realmente finalizar esse pagamento ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Pagar!',
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      }, function () {
        me.conteudoCarregadoPagamento = false;
        me.agendamentoService.executarPagamentoDoctorday(filtro).subscribe(
          response => {
            if (response.success) {
              swal({
                title: 'Consulta paga com sucesso',
                text: 'Sua consulta já foi paga em instantes você receberá a confirmação por e-mail',
                type: 'success'
              }, function () {
                me.conteudoCarregadoPagamento = true;
                me.router.navigate(['dashboard']);
              });
            } else {
              swal('Erro!', response.message, 'error');

            }
            me.conteudoCarregadoPagamento = true;
          },
          error => {
            if (error.status == 500) {
              swal({
                title: 'Erro',
                text: 'Não foi possivel realizar o pagamento',
                type: 'error'
              }, function () {
                me.conteudoCarregadoPagamento = true;
              });
            }
          }
        );
      });
    } else {
      swal({
        title: 'Erro',
        text: 'Você deve Confirmar o Termo de Aceite',
        type: 'error'
      });
    }

  }

  pagarPersonal(pagar: any): any {
    let me = this;

    if (pagar.numero === '') {
      swal('Campo vazio', 'digite o numero do cartão por favor', 'error');
      return false;
    } else if (pagar.nome === '') {
      swal('Campo vazio', 'digite o Nome Impresso no Cartão', 'error');
      return false;
    } else if (pagar.checado == false) {
      swal('Termo de Aceite', 'Por favor , Marque que concorda com o termo de aceite.', 'error');
      return false;
    }

    let filtro: any = me.userService.getPreparaPagamento();
    filtro.cartaonumero = pagar.numero;
    filtro.nomeimpresso = pagar.nome;
    swal({
      title: 'Finalizar pagamento ?',
      text: 'Deseja realmente finalizar esse pagamento ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, Pagar!',
      closeOnConfirm: false,
      showLoaderOnConfirm: true
    }, function () {
      me.conteudoCarregadoPagamento = false;
      me.agendamentoService.executarPagamento(filtro).subscribe(
        response => {
          if (response.success) {
            swal({
              title: 'Consulta paga com sucesso',
              text: 'Sua consulta já foi paga em instantes você receberá a confirmação por e-mail',
              type: 'success'
            }, function () {
              me.conteudoCarregadoPagamento = true;
              me.router.navigate(['dashboard']);
            });
          } else {
            swal('Erro!', response.message, 'error');

          }
          me.conteudoCarregadoPagamento = true;
        },
        error => {
          if (error.status == 500) {
            swal({
              title: 'Erro',
              text: 'Não foi possivel realizar o pagamento',
              type: 'error'
            }, function () {
              me.conteudoCarregadoPagamento = true;
            });
          }
        }
      );
    });
  }

  getDashBoard(): void {
    this.pacienteService.getDashboard({}).subscribe(
      response => {
        this.objetoUsuario = response.dadosUsuario;
      }
    );
  }

  aceitaTermos(): void {
    this.dadoscartao.checado = !this.dadoscartao.checado;
  }

  getDadosCartaoDoctorDay(): void {
    this.dadoscartaoDoctorDay = this.userService.getUser();
  }

  ngOnInit(): any {

    let me = this;
    let verificarPagamentoSetado = true;

    if (me.userService.getPreparaPagamento()) {
      me.arrPrepararPagamento = undefined;
      me.prepararPagamento = me.userService.getPreparaPagamento();
      let valorConvertido  = me.prepararPagamento.valorMedico.replace('.', ',');


      valorConvertido = parseFloat(me.prepararPagamento.valorMedico);
      let n = valorConvertido,
        c = 2,
        d = ',',
        t = '.',
        s = n < 0 ? '-' : '',
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + '',
        j: any;
      j = (j = i.length) > 3 ? j % 3 : 0;

      me.prepararPagamento.valorMedicoBr = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - parseInt(i)).toFixed(c).slice(2) : '');
      me.prepararPagamento.valorMedico = parseFloat(me.prepararPagamento.valorMedico);

      verificarPagamentoSetado = false;
    }

    if (me.userService.getPreparaPagamentoSessao()) {
      me.prepararPagamento = undefined;
      me.arrPrepararPagamento = me.userService.getPreparaPagamentoSessao();
      let valorConvertido: any = String(me.arrPrepararPagamento.valorPagar).replace('.', ',');


      valorConvertido = parseFloat(me.arrPrepararPagamento.valorPagar);
      let n = valorConvertido,
        c = 2,
        d = ',',
        t = '.',
        s = n < 0 ? '-' : '',
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + '',
        j: any;
      j = (j = i.length) > 3 ? j % 3 : 0;

      me.arrPrepararPagamento.valorPagarBr = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - parseInt(i)).toFixed(c).slice(2) : '');
      me.arrPrepararPagamento.valorPagar = parseFloat(me.arrPrepararPagamento.valorPagar);

      verificarPagamentoSetado = false;
    }

    if (verificarPagamentoSetado) {
      me.router.navigate(['dashboard']);
    }

    me.getDashBoard();
    me.getDadosCartaoDoctorDay();


    setTimeout(function () {
      $('.valoresNumeroCartao').mask('00000 0000 0000 0000');
    }, 1);
  }

}
