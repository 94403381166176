import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../services/user.service';

declare let sawl;
@Component({
  selector: 'app-trocar-login',
  templateUrl: './trocar-login.html',
  styleUrls: ['./trocar-login.css']
})

export class TrocarLoginComponent implements OnInit {
  usuario: any = {
    usuario: '',
    senha: '',
    senhanova: '',
    senhaconfirmar: ''
  };

  mostraMsg = false;

  @ViewChild('msgError', {static: false}) msgError: ElementRef;
  @ViewChild('msgErrorCamposVazios', {static: false}) msgErrorCamposVazios: ElementRef;
  @ViewChild('msgErrorSenhaDiferente', {static: false}) msgErrorSenhaDiferente: ElementRef;


  constructor(private router: Router, private userService: UserService) {
  }

  ngOnInit() {
    let objetoAgendamento: any = this.userService.getAgendamento();

    if (objetoAgendamento !== undefined) {
      this.mostraMsg = true;
    }

  }

  trocarSenhaSite(usuario: any): any {
    if (usuario.login != '' && usuario.senha != '' && usuario.senhanova != '' && usuario.senhaconfirmar != '') {
      if (usuario.senhanova == usuario.senhaconfirmar) {
        this.userService.trocarSenhaSite(usuario, false).subscribe(
          response => {
            if (response.success) {
              swal({
                title: 'Senha Trocada com Sucesso',
                text: 'Sua senha foi trocada com sucesso',
                type: 'success',
                showCancelButton: false,
                closeOnConfirm: true
              });
            } else {
              $(this.msgError.nativeElement).show();
            }
          });
      } else {
        $(this.msgErrorSenhaDiferente.nativeElement).show();
      }

    } else {
      $(this.msgErrorCamposVazios.nativeElement).show();
    }
  }

  cadastrar(): any {
    this.router.navigate(['cadastro']);
  }
}
