import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-router-outlet',
  template: `
    <router-outlet></router-outlet>
  `,
  host: {
    '(window:resize)': 'titlePage()'
  }
})

export class RouterOutletComponent {
  constructor(private router: Router) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.titlePage();
      }
    });
  }

  titlePage(): void {
    setTimeout(function () {
      if ($('.page-title h1')) {
        let width = $('body').width();
        $('.page-title h1').css('padding-bottom', ((336 * width) / 1918) - 104);
      }
    }, 1);
  }
}
