import {
  Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, ElementRef,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {PacienteService} from '../../services/paciente.service';

@Component({
  selector: 'relatorio-consulta-agendada',
  templateUrl: './relatorio-consulta-agendada.html'
})
export class RelatorioConsultaAgendada implements OnInit {
  filtro: any;
  arrDados: any;
  ordenacao: any = {};
  conteudoCarregado = true;
  conteudoConsultas = true;
  carregaRelatorio = false;
  caminhoRelatorio = 'assets/reports/relatorio_consultas_agendadas.mrt';
  dadosRelatorio: any = {};
  @ViewChild('filtrosAbrir', {static: false}) filtrosAbrir: ElementRef;

  @ViewChild('dataConsultaContiner', {static: false}) dataConsultaContiner: ElementRef;
  @ViewChild('dataValidadeSubContainer', {static: false}) dataValidadeSubContainer: ElementRef;
  @ViewChild('dataconsulta', {static: false}) dataconsulta: ElementRef;

  @Input() evento;
  @Output() carregado = new EventEmitter<boolean>();
  @Output() editarEsp = new EventEmitter<string>();
  @Output() visualizarEsp = new EventEmitter<string>();

  @Input() permissao: any;

  perfilUsuario = 0;
  constructor( private router: Router,
            private servico: PacienteService ) {

    this.filtro = {
      dataconsulta: '',
      clinica: '',
      medico: ''
    };

  }


  filtrarDataGridLocal(filtros: any): any {
    this.carregaRelatorio = false;
    this.conteudoConsultas = false;
    this.conteudoCarregado = false;

    this.servico.getConsultasAgendadas(filtros)
      .subscribe(
        response => {
          this.conteudoCarregado = true;
          this.conteudoConsultas = true;
          if (response) {
            let dados = { cabec : response.cabec, lista :  response.lista  }
            this.dadosRelatorio = [];
            this.dadosRelatorio = dados;
            this.carregaRelatorio = true;
          } else {
            this.carregaRelatorio = true;
          }

        },
        error => {
          let $this = this;
          if (error.status == 401) {
            swal({
              title: 'Token Expirado!',
              text: 'Por favor, entre novamente no sistema.',
              type: 'error',
              closeOnConfirm: true
            }, function () {
              $this.router.navigate(['']);
            });
          }
        }
      );
  }

  ngOnInit(): any {
    this.filtrarDataGridLocal(this.filtro);

  }

}
