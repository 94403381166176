import {
  Component, Input, ViewEncapsulation, OnInit
} from '@angular/core';

declare var Stimulsoft: any;

@Component({
  selector: 'stimulsoft-report',
  templateUrl: './stimulsoft-report.html',
  encapsulation: ViewEncapsulation.None
})

export class StimulsoftReportComponent implements OnInit {

  viewer: any = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
  report: any = new Stimulsoft.Report.StiReport();

  @Input('dados') dados: any;
  @Input('caminhoRelatorio') caminhoRelatorio: any;

  constructor() {
  }

  ngOnInit() {
    let infos = {
      cabec: this.dados.cabec,
      lista: this.dados.lista,
      footer: this.dados.footer
    }

    let me = this;
    me.report.loadFile(this.caminhoRelatorio);

    setTimeout(function () {
      let dataSet = new Stimulsoft.System.Data.DataSet('DadosRelatorio');
      Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('assets/Localizations/pt-BR.xml');
      // Load JSON data file from specified URL to the DataSet object
      dataSet.readJson(infos);
      // Remove all connections from the report template
      me.report.dictionary.databases.clear();
      // Register DataSet object
      me.report.regData('DadosRelatorio', 'DadosRelatorio', dataSet);
      // Render report with registered data

      me.viewer.renderHtml('viewer');

      me.viewer.report = me.report;

    }, 10);


  }

}
