import {Observable} from 'rxjs/Observable';
import 'rxjs/Rx';
import {Injectable} from '@angular/core';
import {ServiceMain} from './service-main.service';
import {HttpClient} from '@angular/common/http';
import {PersistenceService} from 'angular-persistence';


@Injectable()
export class SendMailService extends ServiceMain {

  constructor(private _http: HttpClient,
              private persistenceService: PersistenceService) {
    super(_http, persistenceService);
  }

  sendMailContato(dataMail): Observable<any> {
    return this.executaGET(dataMail, 'publico/sendmailcontato', false);
  }

  sendMailSolicitarCadastro(dataMail): Observable<any> {
    return this.executaGET(dataMail, 'publico/sendmailsolicitarcadastro', false);
  }

}
