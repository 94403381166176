import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LocalidadeService} from '../services/localidade.service';
import {AgendamentoService} from '../services/agendamento.service';
import {MedicoService} from '../services/medico.service';

@Component({
  selector: 'app-rede',
  templateUrl: './rede.html',
  styleUrls: ['./rede.css']
})

export class RedeComponent implements OnInit {
  entidade: any = {
    estado: '',
    municipio: '',
    bairro: '',
    especialidade: ''
  };

  NomeClinicaShoEspecialidade = '';

  arrEstados: any = [];
  arrMunicipios: any = [];
  arrBairros: any = [];
  arrEspecialidades: any = [];
  arrCredEspecialidades: any = [];
  arrCredenciados: any = [];

  conteudoCarregado = false;
  conteudoCarregadoMunicipio = true;
  conteudoCarregadoBairro = true;
  carregadoCredenciado = false;
  conteudoCarregadoEspecialidades = false;

  objetoCredenciado: any = {};

  conteudoCarregadoUnidades = true;
  conteudoCarregadoUnidadesC = false;

  @ViewChild('mapa', {static: false}) mapa: ElementRef;
  @ViewChild('tipoMapa', {static: false}) tipoMapa: ElementRef;

  constructor(private localidadeService: LocalidadeService,
              private agendamentoService: AgendamentoService,
              private medicoService: MedicoService) {
  }

  getBairros(cidade: any): void {
    this.arrBairros = [];
    this.entidade.bairro = '';
    this.conteudoCarregadoBairro = false;
    this.conteudoCarregadoUnidades = false;
    this.localidadeService.getBairros({municipio: cidade}).subscribe(
      response => {
        this.arrBairros = response;
        this.conteudoCarregadoBairro = true;
        this.selecionarUnidadesMedicas();
      }
    );
  }

  getMunicipios(cuf: any): void {
    this.arrMunicipios = [];
    this.arrBairros = [];
    this.entidade.municipio = '';
    this.entidade.bairro = '';

    this.arrCredenciados = [];
    this.carregadoCredenciado = false;
    if (cuf != '') {
      this.conteudoCarregadoMunicipio = false;
      this.conteudoCarregadoUnidades = false;

      this.localidadeService.getMunicipios({estado: cuf}).subscribe(
        response => {
          this.arrMunicipios = response;
          this.arrBairros = [];
          this.conteudoCarregadoMunicipio = true;
          if ( cuf != '') {
            this.carregadoCredenciado = true;
          }
          this.selecionarUnidadesMedicas();
        }
      );
    }
  }

  getEstados(): void {
    this.conteudoCarregado = false;
    this.arrEstados = [];
    this.arrMunicipios = [];
    this.arrBairros = [];
    this.entidade.municipio = '';
    this.entidade.bairro = '';
    this.entidade.especialidade = '';
    this.entidade.estado = '';
    this.localidadeService.getEstados({}).subscribe(
      response => {
        this.conteudoCarregado = true;
        this.arrEstados = response;
      }
    );
  }

  getListaDatasBairro(bairro): void {
    this.entidade.bairro = bairro;
    this.selecionarUnidadesMedicas();
  }

  ngOnInit(): any {
    let me = this;
    me.conteudoCarregado = false;

    $('.tooltips').tooltip();
    me.getEstados();
    me.getEspecialidades();
  }

  getEspecialidades() {
    this.arrEspecialidades = [];
    this.conteudoCarregado = false;
    this.medicoService.getEspecialidades({}).subscribe(
      response => {
        this.arrEspecialidades = response;
      }
    );
  }

  selecionarUnidadesMedicas(): void {
    this.conteudoCarregadoUnidades = false;
    this.conteudoCarregadoUnidadesC = true;

    let filtro = {
      estado: this.entidade.estado,
      municipio: this.entidade.municipio,
      bairro: this.entidade.bairro,
      especialidade: this.entidade.especialidade,
      isLab: '0'
    };

    this.agendamentoService.getListaUnidade(filtro).subscribe(
      response => {
        let mapCredenciado: any = response.credenciados;
        this.arrCredenciados = [];
        for (let i = 0; i < mapCredenciado.length; i++) {
          if (mapCredenciado[i].latitude != null || mapCredenciado[i].longitude != null ) {
            mapCredenciado[i].latitude *= 1;
            mapCredenciado[i].longitude *= 1;
            mapCredenciado[i].ativoTab = 1;
            mapCredenciado[i].icone = 'https://doctorday.com.br/assets/img/clinica_ico.png';
            this.arrCredenciados.push(mapCredenciado[i]);
          }
        }
        this.conteudoCarregadoUnidades = true;
        let mapa = this.mapa;

        setTimeout(function () {
          let tamanhoCima = $(mapa.nativeElement).offset().top;
          let tamanhoFooter = '95px';
          let margemCard = '30px';
          let paddingCardBody = '25px';
          let ajusteOk = '0px';

          $('.sebm-google-map-container').css('height',
            'calc(100vh - ' + tamanhoCima + 'px - ' + tamanhoFooter + ' - ' + margemCard + ' - ' + paddingCardBody + ' - ' + ajusteOk + ')');

          window.dispatchEvent(new Event('resize'));
        }, 1);



        setTimeout(function () {
          $('.detailed-services dt.opened').next().slideToggle('fast');

          $('.detailed-services').on('click', 'dt', function () {
            $(this).toggleClass('opened').next().slideToggle('fast');
          });
        }, 1);


      });
  }

  carregaEspecialidadesClinica(CodigoPessoaUnidade, NomeClinica): void {
    let $this = this;
    $this.conteudoCarregadoEspecialidades = false;
    $this.NomeClinicaShoEspecialidade = NomeClinica;
    $this.medicoService.getEspecialidadesUnidade({CodigoPessoaUnidade: CodigoPessoaUnidade})
      .subscribe(
        response => {
            if (response.success) {
                $this.arrCredEspecialidades = response.especialidades;
                $this.conteudoCarregadoEspecialidades = true;
            }
        }
      );
  }

}
